import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageRule from '@LuckyGift/images/dialog-bg.webp'
import RuleEn from '@LuckyGift/images/rule-en.png'
import RuleAr from '@LuckyGift/images/rule-ar.png'
import './index.scss'
import { Mask } from 'antd-mobile'

function index() {
  const { t, i18n } = useTranslation()
  const [viewDialog, setViewDialog] = useState(false)

  return (
    <>
      <div className="cmp-rule" onClick={() => setViewDialog(true)}>
        <img className="cmp-rule__bg" src={ImageRule} />
        <span>{t('Common.Rule')}</span>
      </div>
      <Mask className='cmp-rule__dialog' visible={viewDialog} onMaskClick={() => setViewDialog(false)}>
        <div className="cmp-rule__dialog-content">
          <img
            src={i18n.language === 'ar' ? RuleAr : RuleEn}
            className="cmp-rule__dialog-title"
          />
          <div className="cmp-rule__dialog-list">
            {new Array(5).fill(0).map((_, i) => (
              <div className="cmp-rule__dialog-item" key={i}>
                {t(`LuckyGift.Rule.Rule${i + 1}`)}
              </div>
            ))}
          </div>
        </div>
      </Mask>
    </>
  )
}

export default memo(index)
