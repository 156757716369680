import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageHeaderBannerEn from '@LuckyGift/images/header-banner-en.webp'
import ImageHeaderBannerAr from '@LuckyGift/images/header-banner-ar.webp'
import Rule from './components/Rule'
import Records from './components/Records'
import Chest from './components/Chest'
import Countdown from './components/Countdown'
import Rank from './components/Rank'
import { toast } from '@/utils/bridge'
import { getHomeData } from '@LuckyGift/services';
import TabButtons from '@/components/TabButtons';
import clsx from 'clsx'
import Loading from '@/components/Loading';
import BottomStatement from '@/components/BottomStatement';
import './index.scss'

function LuckyGift() {
  const { i18n } = useTranslation()
  const [thisDayData, setThisDayData] = useState({} as LuckyGift.HomeInfo)
  const [loading, setLoading] = useState<boolean>(false)
  /* TabButtons组件 */
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const tabText = useMemo(() => {
    return ['LuckyGift.ThisDay', 'LuckyGift.ThisWeek']
  }, [])
  /* 请求 */
  const fetchThisDay = useCallback(async () => {
    setLoading(true)
    const res = await getHomeData()
    setLoading(false)
    if (!res) return
    const { code, message, data } = res as Response<LuckyGift.HomeInfo>
    if (code !== 200) {
      toast(message)
      return
    }
    data.rank.sort((a, b) => b.totalCoin - a.totalCoin)
    setThisDayData({ ...data })
  }, [])
  useEffect(() => {
    fetchThisDay()
  }, [fetchThisDay])
  return (
    <div className="pg-luckygift">
      <img
        src={i18n.language === 'ar' ? ImageHeaderBannerAr : ImageHeaderBannerEn}
        className="header-banner"
      />
      <Rule />
      <Records />
      <TabButtons selectedTab={selectedTab} setSelectedTab={() => { }} tabText={tabText} >
        {/* this day */}
        <div className={clsx({ hide: selectedTab !== 0 })}>
          <Chest homeData={thisDayData} fetchThisDay={fetchThisDay} />
          <Countdown />
          <Rank rank={thisDayData.rank} user={thisDayData.userRank} />
        </div>
        {/* this week */}
        <div className={clsx({ hide: selectedTab !== 1 })}>
          <Chest homeData={thisDayData} />
          <Countdown />
          <Rank rank={thisDayData.rank} user={thisDayData.userRank} />
        </div>
      </TabButtons>
      <BottomStatement text='Common.Statement' />
      {loading && <Loading />}
    </div>
  )
}
export default LuckyGift
