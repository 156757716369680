import { service } from '@/utils/net'

// 首页
export async function getHomeData() {
  return service<Response<LuckyGift.HomeInfo>>({
    method: 'GET',
    url: 'h5doings/luckGift/activity/home'
  }).then((res) => res?.data)
}
// 领取记录
export async function getRecordData() {
  return service<Response<LuckyGift.RecordInfo[]>>({
    method: 'GET',
    url: 'h5doings/luckGift/activity/record'
  }).then((res) => res?.data)
}

// 开启宝箱
export async function openBox() {
  return service<Response>({
    method: 'POST',
    url: 'h5doings/luckGift/activity/openBox'
  }).then((res) => res?.data)
}