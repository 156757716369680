import { Parser, Player } from 'svga'
import { Modal, Toast } from 'antd-mobile'
import { ParserConfigOptions, PlayerConfig } from 'svga/dist/types'

export function getAuthInfo() {
  let auth
  try {
    const authStr = localStorage.getItem('auth') || '{}'

    auth = JSON.parse(authStr)
  } catch (e) {
    return
  }
  return auth
}

export async function toast(message: string, time = 2) {
  Toast.show({
    content: message
  })

  await new Promise((resolve) => setTimeout(resolve, time * 1000))
  return
}

export function alert(message: string, onConfirm?: () => void) {
  Modal.alert({
    content: message,
    showCloseButton: true,
    onConfirm
  })
}

export async function loadSvga(
  file: string,
  el: HTMLCanvasElement | Partial<PlayerConfig>,
  options?: ParserConfigOptions
) {
  const parser = new Parser(options)
  const svga = await parser.load(file)

  const player = new Player(el)
  await player.mount(svga)

  player.onStart = () => console.log('onStart')
  player.onResume = () => console.log('onResume')
  player.onPause = () => console.log('onPause')
  // player.onStop = () => console.log('onStop')
  // player.onProcess = () => console.log('onProcess')
  player.onEnd = () => console.log('onEnd')

  return player
}

function stopEvent(e: Event) {
  e.preventDefault()
}

export function stopScroll() {
  document.addEventListener('mousewheel', stopEvent, { passive: false })
  document.addEventListener('touchmove', stopEvent, { passive: false })
}

export function resetScroll() {
  document.removeEventListener('mousewheel', stopEvent)
  document.removeEventListener('touchmove', stopEvent)
}

export function px2rem(px: number) {
  return `${px / 37.5}rem`;
}
