import React, { memo } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import ImgCoin from '@/images/coin.png';

function index() {
    const { t } = useTranslation()
    return (
        <div className='cmp-mine'>
            <span className='cmp-mine-title'>{t('Common.Mine')}</span>
            <div className='left'>
                <img src="https://picsum.photos/200" alt="" />
                <div className='info'>
                    <span>Username</span>
                    <span>ID:12345</span>
                </div>
            </div>
            <div className='right'>
                <img src={ImgCoin} alt="" />
                <span>99999</span>
            </div>
        </div>
    )
}

export default memo(index)