import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { getHomeInfo, getQuestion, openBox, answer } from '@RamadanAnswer/services';
import { toast } from '@/utils/bridge';
import { debounce } from 'lodash-es';

import ImgCoinShadow from '@/images/coin-shadow.png'
import ImgCoinGray from '@RamadanAnswer/images/coin-gray.webp'
import ImgGiftBox1 from '@RamadanAnswer/images/gift-box1.webp'
import ImgGiftBox2 from '@RamadanAnswer/images/gift-box2.webp'
import ImgGiftBox3 from '@RamadanAnswer/images/gift-box3.webp'

import { ProgressBar } from 'antd-mobile'
import Dialog from '@/components/Dialog';
import Loading from '@/components/Loading';
interface PropsType {
    setLoading: (update: (prev: number) => number) => void
}
function index(props: PropsType) {
    const { t, i18n } = useTranslation()
    const [viewDialog, setViewDialog] = useState(false)
    const [rewardIcon, setRewardIcon] = useState('')

    const [loading, setLoading] = useState(false)
    const refLoading = useRef(loading)
    refLoading.current = loading

    const [currentState, setCurrentState] = useState<
        {
            questionIndex: number
            nextQuestionNeedCoin: number
            lastCorrectAnswer?: string
            lastUserAnswer?: string
        }>({
            questionIndex: 0,
            nextQuestionNeedCoin: 0
        })
    const refCurrentState = useRef(currentState)
    refCurrentState.current = currentState

    const [selectAnswer, setSelectAnswer] = useState('')
    const refSelectAnswer = useRef(selectAnswer)
    refSelectAnswer.current = selectAnswer

    const [rightAnswer, setRightAnswer] = useState('')
    const refRightAnswer = useRef(rightAnswer)
    refRightAnswer.current = rightAnswer

    const [homeInfo, setHomeInfo] = useState<RamadanAnswer.HomeInfo>()
    const refHomeInfo = useRef(homeInfo)
    refHomeInfo.current = homeInfo

    const [questions, setQuestions] = useState<RamadanAnswer.QuestionInfo[]>([])
    const refQuestions = useRef(questions)
    refQuestions.current = questions

    const rewardsState = useMemo(() => {
        let lvGift = ImgGiftBox1
        let still = 0
        let progress = 0
        if (!homeInfo) return { lvGift, still, progress }
        if (homeInfo.correctNumber < homeInfo.firstLevelCount) {
            still = homeInfo.firstLevelCount - homeInfo.correctNumber
        } else if (homeInfo.correctNumber >= homeInfo.firstLevelCount && homeInfo.correctNumber < homeInfo.secondLevelCount) {
            still = homeInfo.secondLevelCount - homeInfo.correctNumber
        } else if (homeInfo.correctNumber >= homeInfo.secondLevelCount && homeInfo.correctNumber <= homeInfo.threeLevelCount) {
            still = homeInfo.threeLevelCount - homeInfo.correctNumber
        }

        if (homeInfo.correctNumber < homeInfo.secondLevelCount) {
            lvGift = ImgGiftBox1
        } else if (homeInfo.correctNumber < homeInfo.threeLevelCount) {
            lvGift = ImgGiftBox2
        } else {
            lvGift = ImgGiftBox3
        }
        progress = homeInfo.correctNumber / homeInfo.threeLevelCount * 100

        return { lvGift, still, progress }
    }, [homeInfo])

    const fetchHomeInfo = useCallback(async () => {
        const res = await getHomeInfo()
        props.setLoading(prev => prev + 1)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setHomeInfo(data)
    }, [])

    const fetchQuestion = useCallback(async (needCoin?: number) => {
        if (refLoading.current) return
        refLoading.current = true
        const res = await getQuestion(needCoin)
        refLoading.current = false
        props.setLoading(prev => prev + 1)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setCurrentState({
            questionIndex: data.questionIndex,
            nextQuestionNeedCoin: data.needCoin,
            lastCorrectAnswer: data.lastCorrectAnswer,
            lastUserAnswer: data.lastUserAnswer
        })
        setQuestions([
            ...refQuestions.current,
            data
        ])
        setRightAnswer('')
        setSelectAnswer('')
    }, [])

    const handleSelect = useCallback((option: string) => {
        if (refRightAnswer.current || refCurrentState.current.lastCorrectAnswer) return
        setSelectAnswer(option)
    }, [])

    const handleOpen = useCallback(debounce(async () => {
        if (!refHomeInfo.current?.canOpenBox || refLoading.current) return
        setLoading(true)
        const res = await openBox()
        setLoading(false)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setRewardIcon(data.rewardIcon)
        setViewDialog(true)
        setTimeout(() => {
            fetchHomeInfo()
        }, 300);
    }, 500), [])

    const handleNext = useCallback(debounce(() => {
        if (!refRightAnswer.current && !refCurrentState.current.lastCorrectAnswer) return
        fetchQuestion(refCurrentState.current.nextQuestionNeedCoin)
    }, 300), [])

    const handleConfirm = useCallback(debounce(async (questionId: number) => {
        if (!refSelectAnswer.current || refRightAnswer.current || refCurrentState.current.lastCorrectAnswer || refLoading.current) return
        setLoading(true)
        const res = await answer({
            answer: refSelectAnswer.current,
            questionId
        })
        setLoading(false)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        setRightAnswer(data.correctAnswer)
        setTimeout(() => {
            fetchHomeInfo()
        }, 500);
    }, 500), [])

    useEffect(() => {
        props.setLoading(() => 0)
        fetchHomeInfo().then(() => {
            fetchQuestion()
        })
    }, [])
    return (
        <div className='cmp-questions'>
            {loading && <Loading />}
            <div className='cmp-questions__dally-rewards'>
                <span className='cmp-questions__dally-rewards-title'>
                    {t('RamadanAnswer.DallyRewards')}
                </span>
                <img className='cmp-questions__dally-rewards-open-gift-box' src={rewardsState.lvGift} alt="" />
                <button disabled={!homeInfo?.canOpenBox} className={clsx('cmp-questions__dally-rewards-btn', { 'can-open': homeInfo?.canOpenBox })} onClick={handleOpen}>{t('Common.Open')}</button>
                <div className='cmp-questions__dally-rewards-tip' dangerouslySetInnerHTML={{ __html: t('RamadanAnswer.UpgradeChestLevel', { surplus: rewardsState.still }).toString() }} />
                <div className='cmp-questions__dally-rewards-progress'>
                    <div className='cmp-questions__dally-rewards-progress-gifts'>
                        <img src={ImgGiftBox1} alt="" />
                        <img src={ImgGiftBox2} alt="" />
                        <img src={ImgGiftBox3} alt="" />
                    </div>
                    <div className='cmp-questions__dally-rewards-progress-content'>
                        <ProgressBar percent={rewardsState.progress} />
                        <span className='cmp-questions__dally-rewards-progress-num' style={{ 'left': `${i18n.language === 'en' ? `calc(${rewardsState.progress}% - 1%)` : 'unset'}`, 'right': `${i18n.language === 'ar' ? `calc(${rewardsState.progress}% - 1%)` : 'unset'}` }}>
                            {homeInfo?.correctNumber}
                        </span>
                    </div>
                </div>
            </div>
            <div className='cmp-questions__answer-questions'>
                <span className='cmp-questions__answer-questions-title'>
                    {t('RamadanAnswer.AnswerQuestions')}
                </span>
                {!!questions.length &&
                    questions.map((item, i) =>
                        <div key={i} className={clsx('cmp-questions__answer-questions-content', `${currentState.questionIndex === item.questionIndex ? 'question-appear' : 'question-hide'}`)}>
                            <span className='cmp-questions__answer-questions-sequence'>({item.questionIndex}/3)</span>
                            <span className='cmp-questions__answer-questions-question'>
                                {item.questionIndex + '.' + item.question}
                            </span>
                            <div onClick={() => handleSelect('A')} className={clsx('cmp-questions__answer-questions-select-item', `${(currentState.lastCorrectAnswer === 'A' || rightAnswer === 'A') && 'right'}`, `${((currentState.lastCorrectAnswer && currentState.lastCorrectAnswer !== 'A') || (rightAnswer && rightAnswer !== 'A')) && 'wrong'}`, { selected: selectAnswer === 'A' || currentState.lastUserAnswer === 'A' })}>
                                <div></div>
                                <span>{item.answerA}</span>
                            </div>
                            <div onClick={() => handleSelect('B')} className={clsx('cmp-questions__answer-questions-select-item', `${(currentState.lastCorrectAnswer === 'B' || rightAnswer === 'B') && 'right'}`, `${((currentState.lastCorrectAnswer && currentState.lastCorrectAnswer !== 'B') || (rightAnswer && rightAnswer !== 'B')) && 'wrong'}`, { selected: selectAnswer === 'B' || currentState.lastUserAnswer === 'B' })}>
                                <div></div>
                                <span>{item.answerB}</span>
                            </div>
                            <button disabled={Boolean(!selectAnswer) || Boolean(rightAnswer) || Boolean(currentState.lastCorrectAnswer)} className={clsx('cmp-questions__answer-questions-btn', { 'can-open': selectAnswer && !rightAnswer && !currentState.lastCorrectAnswer })} onClick={() => handleConfirm(item.questionId)}>{t('Common.Confirm')}</button>
                        </div>
                    )
                }
            </div>
            {currentState.questionIndex < 3 &&
                <button className={clsx('cmp-questions__next-btn', { 'can-next': rightAnswer || currentState.lastCorrectAnswer })} onClick={handleNext}>
                    <span>{t('RamadanAnswer.NextQuestions')}</span>
                    <img src={(rightAnswer || currentState.lastCorrectAnswer) ? ImgCoinShadow : ImgCoinGray} alt="" />
                    <span>50</span>
                </button>
            }
            <Dialog viewDialog={viewDialog} setViewDialog={setViewDialog} title="Common.Congratulations" >
                <div className='cmp-questions__dialog-content'>
                    <div>
                        <img src={rewardIcon} alt="" />
                    </div>
                    <button onClick={() => setViewDialog(false)}>{t('Common.Confirm')}</button>
                </div>
            </Dialog>
        </div >
    )
}

export default memo(index)