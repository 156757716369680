import { service } from '@/utils/net'

// 榜单
export async function getRank(rankType: number) {
  return service<Response<CharmAnchor.Rank>>({
    method: 'GET',
    url: 'h5doings/GlamourAnchor/activity/rank',
    params: {
      rankType
    }
  }).then((res) => res?.data)
}