import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import ImageBannerEn from '@RechargeSendMedal/images/banner-en.webp'
import ImageBannerAr from '@RechargeSendMedal/images/banner-ar.webp'
import BottomStatement from '@/components/BottomStatement';
import Mine from './components/Mine';
import Rule from './components/Rule';
import Rewards from './components/Rewards';
function RechargeSendMedal() {
    const { i18n } = useTranslation()
    return (
        <div className="pg-recharge_send_medal">
            <img
                src={i18n.language === 'ar' ? ImageBannerAr : ImageBannerEn}
                className="banner"
            />
            <main>
                <Rule />
                <Mine />
                <Rewards />
                <BottomStatement text='Common.Statement' />
            </main>
        </div>
    )
}
export default RechargeSendMedal
