import React, { memo, useCallback } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { Popup } from 'antd-mobile'
import ImageFaceBook from '@Invite/images/facebook.webp'
import ImageWhatApp from '@Invite/images/whats-app.webp'
import ImageCopyLink from '@Invite/images/copy-link.webp'
import { shareApp } from '@/utils/bridge'
import { t } from 'i18next'
import { downloadLink } from '@/utils/constant'

interface ShareProps {
  openView: (v: boolean) => void
  handleCopy: (data: string) => void
  view: boolean
}

const shareDataList = [
  { text: t('Invite.Facebook'), type: 'facebook', img: ImageFaceBook },
  { text: t('Invite.WhatsApp'), type: 'whatsapp', img: ImageWhatApp },
  { text: t('Invite.CopyLink'), type: 'copy', img: ImageCopyLink }
]
function index(props: ShareProps) {
  const { view, openView, handleCopy } = props
  const { t } = useTranslation()

  const handleShare = useCallback((type: string) => {
    if (type === 'copy') {
      handleCopy(downloadLink)
      return
    }
    shareApp(type)
  }, [])
  return (
    <Popup
      visible={view}
      className="share"
      onMaskClick={() => {
        openView(false)
      }}>
      <div className="share-content">
        <div className="title">{t('Invite.Share')}</div>
        <div className="share-list">
          {shareDataList.map((item, i) => (
            <div
              className="share-list-item"
              key={i}
              onClick={() => handleShare(item.type)}>
              <img src={item.img} />
              <span>{item.text}</span>
            </div>
          ))}
        </div>
      </div>
    </Popup>
  )
}

export default memo(index)
