import React, { memo, useState } from 'react'
import './index.scss'
import Dialog from '@/components/Dialog';
import { useTranslation } from 'react-i18next';

function index() {
    const { t } = useTranslation()
    const [viewDialog, setViewDialog] = useState(false)
    return <>
        <div className='cmp-rule' onClick={() => setViewDialog(true)}>
            <Dialog viewDialog={viewDialog} setViewDialog={setViewDialog} title="Common.Rule">
                <div className='text'>
                    {new Array(8).fill(0).map((_, i) =>
                        <span key={i}>{t(`RechargeSendMedal.Rule.Rule${i + 1}`)}</span>
                    )}
                </div>
            </Dialog>
        </div>

    </>
}

export default memo(index)