import React, { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'
import ImgRankTitleAr from '@LuckyGift/images/rank-ar.webp'
import ImgRankTitleEn from '@LuckyGift/images/rank-en.webp'
import ImgGoldIcon from '@LuckyGift/images/gold-icon.webp'
import ImgRankEmpty from '@LuckyGift/images/rank-empty.webp'
import Empty from '@/components/Empty';
import { px2rem } from '@/utils/tool';
import { cloneDeep } from 'lodash-es'
import clsx from 'clsx'

interface PropsType {
    rank: LuckyGift.RankItem[]
    user: LuckyGift.RankItem
}

function index({ rank = [], user = {} as LuckyGift.RankItem }: PropsType) {
    const { t, i18n } = useTranslation()
    const [rankList, setRankList] = useState<National.RankItem[]>([])
    const [userRank, setUserRank] = useState<National.RankItem>({} as LuckyGift.RankItem)
    useEffect(() => {
        const rankData = cloneDeep(rank.map((item, index) => { return { ...item, index: index + 1 } }))
        const userData = cloneDeep(user)
        const userIndex: number = rankData.findIndex(item => item.uid === userData.uid)
        if (userIndex !== -1) {
            userData.index = rankData[userIndex].index
        } else {
            userData.index = 0
        }
        if (rank.length === 0 || Object.values(user).length === 0) return
        setRankList(rankData.slice(0, 7))
        setUserRank(userData)
    }, [rank, user])
    return <>
        <div className='rank'>
            <img className='title' src={i18n.language === 'ar' ? ImgRankTitleAr : ImgRankTitleEn}></img>
            <div className="list">
                {rankList.length === 0 ? (<Empty image={ImgRankEmpty} imageStyle={{ width: px2rem(120), height: px2rem(92) }} />) :
                    <>
                        {rankList.map(item => (
                            <div className="item" key={item.uid}>
                                <div className='rank-num'>{item.index ? item.index > 3 ? item.index : '' : ''}</div>
                                <img className="avatar" src={item.avatar}></img>
                                <div className="info">
                                    <span className='name'>{item.nick}</span>
                                    <div className="coins">
                                        <span>{t('LuckyGift.BetCoins') + ':'}</span>
                                        <div className="gold">
                                            <img src={ImgGoldIcon}></img>
                                            <span>{item.totalCoin}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="item user">
                            <div className={clsx('rank-num', { 'rank-num1': userRank.index === 1, 'rank-num2': userRank.index === 2, 'rank-num3': userRank.index === 3 })}>{userRank.index === 0 ? '-' : userRank.index && userRank.index > 3 ? userRank.index : ''}</div>
                            <img className="avatar" src={userRank.avatar}></img>
                            <div className="info">
                                <span className='name'>{userRank.nick}</span>
                                <div className="coins">
                                    <span>{t('LuckyGift.BetCoins') + ':'}</span>
                                    <div className="gold">
                                        <img src={ImgGoldIcon}></img>
                                        <span>{userRank.totalCoin}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div >
    </>
}

export default memo(index)