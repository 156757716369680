import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import config from '@/config'
import { getUserInfo, toast } from './bridge'
import { t } from 'i18next'

const axiosInstance = axios.create({
  baseURL: config.net.host,
  timeout: 30000
})

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const auth = await getUserInfo()
    config.params = {
      ...config.params,
      ...auth,
      ...['lang', 'deviceId'].reduce<{ [x: string]: string }>((ret, d) => {
        switch (d) {
          case 'lang':
            ret.language = localStorage.getItem(d) || ''
            break
          default:
            ret[d] = localStorage.getItem(d) || ''
        }
        return ret
      }, {})
    }
    config.headers.uid = auth?.uid
    config.headers.ticket = auth?.ticket
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse<Response>) => {
    return response
  },
  (error: AxiosError) => {
    let reason = 'unknown'
    // 处理网络超时
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      toast(t('Common.NetworkError'))
      reason = 'timeout'
    } else {
      toast(t('Common.SystemError'))
    }
  }
)

export async function service<T = any, D = any, R = AxiosResponse<T, D>>(
  config: AxiosRequestConfig<D> & {
    track?: { name: string; ext?: { [key: string]: any } }
  }
): Promise<R> {
  // const st = Date.now()
  return await axiosInstance(config)
  // const et = Date.now()
  // if (config.track && config.track.name) {
    // window.ta.track('ga_api', {
    //   name: config.track.name,
    //   url: config.url,
    //   method: (config.method || 'GET').toUpperCase(),
    //   params: JSON.stringify(config.params || {}),
    //   data: JSON.stringify(config.data || {}),
    //   duration: et - st
    // })
  // }
}
