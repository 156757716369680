import React, { memo, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import TabButtons from '@RechargeSendMedal/components/TabButtons';
import clsx from 'clsx';
import Medal from './components/Medal';
import GetDialog from './components/GetDialog';

function index() {
    const { t } = useTranslation()
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [viewDialog, setViewDialog] = useState(false)
    return (
        <div className='cmp-rewards'>
            <span className='cmp-rewards-title'>{t('Common.Rewards')}</span>
            <TabButtons selectedIndex={selectedIndex} onChange={setSelectedIndex} />
            <div className='cmp-rewards-item'>
                <div className='cmp-rewards-item__title'><span>Grand total this month top up 900k coins</span></div>
                <div className='cmp-rewards-item__content'>
                    <Medal />
                    <Medal />
                    <button className={clsx('cmp-rewards-item__get', { 'get-ac': true })}></button>
                </div>
            </div>
            <GetDialog viewDialog={viewDialog} setViewDialog={setViewDialog} />
        </div>
    )
}

export default memo(index)