import { service } from '@/utils/net'

// 首页
export async function getHomeInfo(): Promise<Response<RamadanAnswer.HomeInfo>> {
  return service<Response<RamadanAnswer.HomeInfo>>({
    method: 'GET',
    url: 'h5doings/quiz/activity/home'
  }).then((res) => res?.data)
}
// 开宝箱
export async function openBox(): Promise<Response<{ rewardIcon: string }>> {
  return service<Response<{ rewardIcon: string }>>({
    method: 'POST',
    url: 'h5doings/quiz/activity/openBox'
  }).then((res) => res?.data)
}
// 获取题目
export async function getQuestion(needCoin?: number): Promise<Response<RamadanAnswer.QuestionInfo>> {
  return service<Response<RamadanAnswer.QuestionInfo>>({
    method: 'GET',
    url: 'h5doings/quiz/activity/getQuestion',
    params: {
      needCoin
    }
  }).then((res) => res?.data)
}
// 答题
export async function answer(params: { answer: string, questionId: number }): Promise<Response<{ correctAnswer: string }>> {
  return service<Response<{ correctAnswer: string }>>({
    method: 'POST',
    url: 'h5doings/quiz/activity/answer',
    params
  }).then((res) => res?.data)
}
// 榜单
export async function getRankList(): Promise<Response<{ userRank: RamadanAnswer.RankList, rankList: RamadanAnswer.RankList[] }>> {
  return service<Response<{ userRank: RamadanAnswer.RankList, rankList: RamadanAnswer.RankList[] }>>({
    method: 'GET',
    url: 'h5doings/quiz/activity/rank'
  }).then((res) => res?.data)
}