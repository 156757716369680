import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import './index.scss'
import ImageTop1 from '@Invite/images/top1-title.webp'
import ImageTop2 from '@Invite/images/top2-title.webp'
import ImageTop3 from '@Invite/images/top3-title.webp'
import { toast } from '@/utils/bridge'
import { t } from 'i18next'
import { getRankReward } from '@Invite/services'
import { Tabs, Swiper } from 'antd-mobile'

const goodsType = { frame: 1, ride: 2, bubble: 4 }
const renderItem = (data: Invite.RankRewardItem[], rank: number) => {
  return (
    <div className="cmp-rewards__box">
      <img
        src={rank === 1 ? ImageTop1 : rank === 2 ? ImageTop2 : ImageTop3}
        className="title"
      />
      <div className="cmp-rewards__box-list">
        {data.map((item, i) => (
          <div className="cmp-rewards__box-item" key={i}>
            <div className="bg">
              <img src={item.picUrl} />
            </div>
            {item.goodsType === goodsType.frame && (
              <span>{t('Invite.Farmedays', { num: item.days })}</span>
            )}
            {item.goodsType === goodsType.ride && (
              <span>{t('Invite.Ridedays', { num: item.days })}</span>
            )}
            {item.goodsType === goodsType.bubble && (
              <span>{t('Invite.Bubbledays', { num: item.days })}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const tabKeys = ['Income', 'Incitation']
function Rewards() {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [rewards, setRewards] = useState<{
    income: Invite.RankRewardItem[]
    incitation: Invite.RankRewardItem[]
  }>({
    income: [],
    incitation: []
  })
  const refSwiper = useRef<React.ElementRef<typeof Swiper>>(null)

  const fetchRank = useCallback(async () => {
    const [resIncome, resIncitation] = await Promise.all([
      getRankReward({ type: 1 }),
      getRankReward({ type: 2 })
    ])

    if (!resIncome || !resIncitation) return

    if (resIncome.code !== 200) {
      toast(resIncome.message)
      return
    }

    if (resIncitation.code !== 200) {
      toast(resIncitation.message)
      return
    }

    const dataIncome = resIncome.data.sort((a, b) => a.top - b.top)
    const dataIncitation = resIncitation.data.sort((a, b) => a.top - b.top)
    setRewards({
      income: dataIncome,
      incitation: dataIncitation
    })
  }, [])

  const handleTabChange = useCallback((key: string) => {
    const index = tabKeys.findIndex((item) => item === key)
    setSelectedTab(index)
    refSwiper.current?.swipeTo(index)
  }, [])

  useEffect(() => {
    fetchRank()
  }, [])

  return (
    <div className="cmp-rewards">
      <Tabs activeKey={tabKeys[selectedTab]} onChange={handleTabChange}>
        {tabKeys.map((key) => (
          <Tabs.Tab title={t(`Invite.${key}`)} key={key} />
        ))}
      </Tabs>
      <Swiper
        direction="horizontal"
        loop
        indicator={() => null}
        ref={refSwiper}
        defaultIndex={0}
        onIndexChange={setSelectedTab}>
        <Swiper.Item>
          <div className="cmp-rewards__content">
            {renderItem(rewards.income.slice(0, 3) || [], 1)}
            {renderItem(rewards.income.slice(3, 6) || [], 2)}
            {renderItem(rewards.income.slice(6, 9) || [], 3)}
          </div>
        </Swiper.Item>
        <Swiper.Item>
          <div className="cmp-rewards__content">
            {renderItem(rewards.incitation.slice(0, 3) || [], 1)}
            {renderItem(rewards.incitation.slice(3, 6) || [], 2)}
            {renderItem(rewards.incitation.slice(6, 9) || [], 3)}
          </div>
        </Swiper.Item>
      </Swiper>
    </div>
  )
}

export default memo(Rewards)
