import React, { memo } from 'react'
import './index.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type TabButtonsProps = {
  selectedTab: number
  setSelectedTab: (i: number) => void
  tabText: string[]//文本
  children?: React.ReactNode
  className?: string
}

function TabButtons({ selectedTab, setSelectedTab, tabText, children, className }: TabButtonsProps) {
  const { t } = useTranslation()
  return (
    <div className={clsx("cmp-tab-buttons", className)}>
      <div className='cmp-tab-buttons-btn'>
        {tabText.map((item, key) => (
          <div
            className={clsx('cmp-tab-buttons-btn__item', { selected: selectedTab === key })}
            key={key}
            onClick={() => setSelectedTab(key)}>
            <span className='cmp-tab-buttons-btn__text'>{t(item)}</span>
          </div>
        ))}
      </div>
      <div className='cmp-tab-buttons-children'>
        {children}
      </div>
    </div>
  )
}

export default memo(TabButtons)
