import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'
import clsx from 'clsx'
import { Mask } from 'antd-mobile'

interface PropsType {
  viewDialog: boolean
  setViewDialog: (viewDialog: boolean) => void
  children: React.ReactNode
  viewClose?: boolean//是否显示关闭按钮
  title?: string//标题
}

function Dialog({ viewDialog, setViewDialog, viewClose, title, children }: PropsType) {
  const { t } = useTranslation()
  return (
    <div className='cmp-dialog'>
      <Mask visible={viewDialog} onMaskClick={() => setViewDialog(false)} opacity={0.6}>
        <div className="cmp-dialog__content">
          <span className="cmp-dialog__title">{t(title ? title : '')}</span>
          <div className={clsx("cmp-dialog__close", { hide: !viewClose })} onClick={() => setViewDialog(false)}></div>
          {children}
        </div>
      </Mask>
    </div>
  )
}

export default memo(Dialog)
