import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageHeaderBannerEn from '@Invite/images/header-banner.webp'
import ImageHeaderBannerAr from '@Invite/images/header-banner-ar.webp'
import Rule from './components/Rule'
import InviteReward from './components/InviteReward'
import Tabs from './components/TabButtons'
import BindInvitation from './components/BindInvitation'
import MyInvitation from './components/MyInvitation'
import Rank from './components/Rank'
import './index.scss'

function Invite() {
  const [t, i18n] = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(0)

  return (
    <div className="pg-invite">
      <img
        src={i18n.language === 'ar' ? ImageHeaderBannerAr : ImageHeaderBannerEn}
        className="header-banner"
      />
      <Rule />
      <InviteReward />
      <Tabs selectedIndex={selectedTab} onChange={setSelectedTab} />
      {selectedTab === 0 && <BindInvitation />}
      {selectedTab === 1 && <MyInvitation />}
      {selectedTab === 2 && <Rank />}
      <div className="bottom__tip">{t('Invite.ExplanationTip', { APP: process.env.REACT_APP_DISPLAY_NAME })}</div>
    </div>
  )
}
export default memo(Invite)
