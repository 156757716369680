import React, { memo } from 'react'
import Dialog from '@/components/Dialog';
import './GetDialog.scss'
import Medal from './Medal';
import ImgGetAc from '@RechargeSendMedal/images/get-ac.webp';

type PropsType = {
    viewDialog: boolean
    setViewDialog: (viewDialog: boolean) => void
}
function GetDialog({ viewDialog, setViewDialog }: PropsType) {
    return (
        <div className='cmp-get-dialog'>
            <Dialog viewDialog={viewDialog} setViewDialog={setViewDialog} title='RechargeSendMedal.Congrats'>
                <div className='medal-box'>
                    <Medal />
                    <Medal />
                </div>
                <img src={ImgGetAc} alt="" className='get' onClick={() => setViewDialog(false)} />
            </Dialog>
        </div>
    )
}

export default memo(GetDialog)