import React, { memo } from 'react'
import './index.scss'
import clsx from 'clsx'
import { openPage } from '@/utils/bridge'
import { PageConst } from '@/utils/constant'
import ImageTop1 from '@Invite/images/top1.webp'
import ImageTop2 from '@Invite/images/top2.webp'
import ImageTop3 from '@Invite/images/top3.webp'

type RankTopProps = {
  item: Invite.RankItem
  icon: string
  index: number
}

const ImageTops = [undefined, ImageTop1, ImageTop2, ImageTop3]
function RankTop(props: RankTopProps) {
  const { item, index } = props

  return (
    <>
      <div
        className={clsx('cmp-top3', {
          top1: index === 1,
          top2: index === 2,
          top3: index === 3
        })}>
        <img
          src={item.avatar}
          className="cmp-top3__avatar"
          onClick={() => openPage(PageConst.PersonalHome, item.uid)}
        />
        <img
          className="cmp-top3__cover"
          src={ImageTops[index]}
          onClick={() => openPage(PageConst.PersonalHome, item.uid)}
        />
        <div className="cmp-top3__info">
          <span>{item.nick}</span>
          <div className="cmp-top3__info-coin">
            <img src={props.icon} />
            <span>{item.number}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(RankTop)
