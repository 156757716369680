import React from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next';

import ImgRewardsEn from '@RamadanAnswer/images/rewards-en.webp';
import ImgRewardsAr from '@RamadanAnswer/images/rewards-ar.webp';

function index() {
    const { i18n } = useTranslation()
    return (
        <div className='cmp-rewards'>
            <img src={i18n.language === 'ar' ? ImgRewardsAr : ImgRewardsEn} alt="" />
        </div>
    )
}

export default index