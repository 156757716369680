import React, {
  memo,
  useCallback,
  ChangeEvent,
  useState,
  useRef,
  useEffect
} from 'react'
import './index.scss'
import ImageBlindBox1G from '@Invite/images/blind-box1-gray.webp'
import ImageBlindBox1 from '@Invite/images/blind-box1.webp'
import ImageBlindBox2G from '@Invite/images/blind-box2-gray.webp'
import ImageBlindBox2 from '@Invite/images/blind-box2.webp'
import ImageBlindBox3G from '@Invite/images/blind-box3-gray.webp'
import ImageBlindBox3 from '@Invite/images/blind-box3.webp'
import { SpinLoading } from 'antd-mobile'
import clsx from 'clsx'
import { t } from 'i18next'
import { toast } from '@/utils/bridge'
import { bindInviteCode, choicePackage, getIndex } from '../../services'
import moment from 'moment'
import i18n from '@/components/i18n'
import Loading from '@/components/Loading';

const blindBoxGrays = [ImageBlindBox1G, ImageBlindBox2G, ImageBlindBox3G]
const blindBox = [ImageBlindBox1, ImageBlindBox2, ImageBlindBox3]
const rewardStatus = { noUser: 0, hasUser: 1, done: 2 }
function index() {
  const [state, setState] = useState<number>(rewardStatus.noUser)

  const [bindInfo, setBindInfo] = useState<Invite.IndexInfo>()

  const [viewDialog, setViewDialog] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [fullscreenLoading, setFullscreenLoading] = useState<boolean>(false)
  const refFullscreenLoading = useRef(fullscreenLoading)
  refFullscreenLoading.current = fullscreenLoading

  const [currentReward, setCurrentReward] = useState<Invite.PackagesItem[]>([])
  const refCurrentReward = useRef(currentReward)
  refCurrentReward.current = currentReward

  const [inviteCode, seInviteCode] = useState<string>('')
  const refInviteCode = useRef(inviteCode)
  refInviteCode.current = inviteCode

  const hanndleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => seInviteCode(e.target.value),
    []
  )
  const fetchIndexData = useCallback(async () => {
    setLoading(true)
    const res = await getIndex()
    setLoading(false)
    if (!res) return

    const { code, message, data } = res as Response<Invite.IndexInfo>
    if (code !== 200) {
      toast(message)
      return
    }
    const isAllNull = Object.values(data).every((value) => value === null)
    if (!isAllNull) {
      setBindInfo(data)
      if (data?.choicePackages?.length) {
        setState(rewardStatus.done)
        return
      }
      setState(rewardStatus.hasUser)
    }
  }, [])

  useEffect(() => {
    fetchIndexData()
  }, [fetchIndexData])

  const openRewardDialog = useCallback(
    (i: number) => {
      if (state !== rewardStatus.hasUser) return
      const newData = bindInfo?.packages.filter((item) => item.packageNum === i)
      setCurrentReward(newData || [])
      setViewDialog(true)
    },
    [bindInfo]
  )

  const handleBindCode = useCallback(async () => {
    if (refInviteCode.current?.length !== 8 || refFullscreenLoading.current) return
    setFullscreenLoading(true)
    const res = await bindInviteCode({ inviteCode: refInviteCode.current })
    setFullscreenLoading(false)
    if (!res) return

    const { code, message } = res as Response
    if (code !== 200) {
      toast(message)
      return
    }
    toast(t('Common.Success'))
    setViewDialog(false)
    fetchIndexData()
  }, [])

  const handleChoicePackage = useCallback(async () => {
    const res = await choicePackage({
      packageNum: refCurrentReward.current?.[0]?.packageNum
    })
    if (!res) return

    const { code, message } = res as Response
    if (code !== 200) {
      toast(message)
      return
    }
    toast(t('Common.Success'))
    setViewDialog(false)
    fetchIndexData()
  }, [])

  return (
    <div className="bind_invitation">
      <div className="title">{t('Invite.ImNewUser')}</div>
      {fullscreenLoading && <Loading />}
      {loading ? (
        <SpinLoading className="spin-loading" />
      ) : (
        <>
          <div className="common-tip">
            {state === rewardStatus.noUser
              ? t('Invite.InputTip')
              : t('Invite.YourInviter')}
          </div>

          {state === rewardStatus.noUser && (
            <>
              <input
                type="text"
                onChange={hanndleChange}
                value={inviteCode}
                placeholder={t('Invite.PleaseEnter').toString()}
              />
              <div
                className={clsx('confirm-btn', {
                  disabled: inviteCode?.length !== 8
                })}
                onClick={handleBindCode}>
                {t('Common.Confirm')}
              </div>
            </>
          )}

          {state !== rewardStatus.noUser && (
            <div className="user-info">
              <img src={bindInfo?.inviterAvatar} />
              <div className="user-info-nick">
                <p>{bindInfo?.inviterNick}</p>
                <p>ID: {bindInfo?.inviterErbanNo}</p>
              </div>
            </div>
          )}
          <div className="bind_invitation-select__tip">
            <div className="diamond" />
            <div className="common-tip">
              {state !== rewardStatus.done
                ? t('Invite.BlindGiftTip')
                : t('Invite.YourChoose')}
            </div>
            <div className="diamond" />
          </div>
          {/* 礼盒 */}
          <div className="blind-boxs">
            {state !== rewardStatus.done ? (
              <>
                {(state === rewardStatus.noUser ? blindBoxGrays : blindBox).map(
                  (item, i) => (
                    <img
                      key={i}
                      src={item}
                      onClick={() => openRewardDialog(i + 1)}
                    />
                  )
                )}
              </>
            ) : (
              <div className="my-choose">
                {bindInfo?.choicePackages.map((item, i) => (
                  <div className="my-choose-item" key={i}>
                    <div className="bg">
                      <img src={item.picUrl} />
                    </div>
                    <span>{item.goodsName}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="common-tip bottom-tip">
            {state !== rewardStatus.done
              ? t('Invite.NewUSerBottomTip')
              : `${t('Invite.ChoiceOfTime')} ${moment(
                bindInfo?.choiceTime
              ).format('MM-DD HH:mm:ss')}`}
          </div>
        </>
      )}

      {/* 弹框 */}
      <div className={clsx('get__dialog', { hide: !viewDialog })}>
        <div
          className={clsx('get__dialog-body', { ar: i18n.language === 'ar' })}>
          <div className="pre-choose">
            {currentReward.map((item, i) => (
              <div className="pre-choose-item" key={i}>
                <div className="bg">
                  <img src={item.picUrl} />
                </div>
                <span>{item.goodsName}</span>
              </div>
            ))}
          </div>
          <div className="operate">
            <div
              className="confirm-btn cancel-btn"
              onClick={() => setViewDialog(false)}>
              {t('Common.Cancel')}
            </div>
            <div className="confirm-btn" onClick={handleChoicePackage}>
              {t('Common.Confirm')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(index)
