import React, { memo } from 'react'
import './index.scss'
import ImageCoin from '@Invite/images/coin.png'
import clsx from 'clsx'
import { InfiniteScroll } from 'antd-mobile'
import { tabKey } from '../Detail'
import { openPage } from '@/utils/bridge'
import { PageConst } from '@/utils/constant'

interface DetailListProps {
  tabVal: number
  loadMore: any
  hasMore: boolean
  data: Invite.DetailListItem[]
}

function index(props: DetailListProps) {
  const { tabVal, data = [], loadMore, hasMore } = props
  return (
    <div
      className={clsx('detail-list', {
        'gold-detail-list': tabVal === 2
      })}>
      {data?.map((item, i) => (
        <div className="detail-list-item" key={i}>
          <div className="detail-list-item-left">
            <img
              src={item.avatar}
              onClick={() => openPage(PageConst.PersonalHome, item.uid)}
            />
            <div className="detail-list-item-left-nick">
              <p>{item.nick}</p>
              <p>ID: {item.erbanNo}</p>
            </div>
          </div>
          {tabVal === tabKey.goldRevenue && (
            <div className="detail-list-item-right">
              <img src={ImageCoin} />
              <span>{item.number}</span>
            </div>
          )}
          {tabVal === tabKey.goldDetail && (
            <div
              className={clsx('detail-list-item-right', {
                'gold-detail': tabVal === tabKey.goldDetail
              })}>
              <div className="coin">
                <img src={ImageCoin} />
                <span>+{item.number}</span>
              </div>
              <span className="time">2023-03-06 19:22:22</span>
            </div>
          )}
        </div>
      ))}

      {data.length !== 0 && (
        <InfiniteScroll loadMore={() => loadMore()} hasMore={hasMore} />
      )}
    </div>
  )
}

export default memo(index)
