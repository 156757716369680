import React, { memo } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'

function index() {
    const { t } = useTranslation()
    return (
        <div className='cmp-rule'>
            {new Array(7).fill(0).map((_, i) =>
                <span key={i}>{t(`GameRankings.Rule.Rule${i + 1}`, { APP: process.env.REACT_APP_DISPLAY_NAME })}</span>
            )}
        </div>
    )
}

export default memo(index)