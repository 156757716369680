import { service } from '@/utils/net'

// 奖池
export async function getRewardsPool(): Promise<
  Response<RoomSupport.RewardsPool>
> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRewardsPool'
  }).then((res) => res?.data)
}

// 开宝箱
export async function getReWardsList(): Promise<
  Response<RoomSupport.RewardItem[]>
> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRewards'
  }).then((res) => res?.data)
}

// 榜单
export async function getRank(): Promise<Response<RoomSupport.RankResponse>> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRank'
  }).then((res) => res?.data)
}
