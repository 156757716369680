import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageRule from '@LuckyGift/images/dialog-bg.webp'
import RecordsEn from '@LuckyGift/images/records-en.webp'
import RecordsAr from '@LuckyGift/images/records-ar.webp'
import './index.scss'
import { getRecordData } from '@LuckyGift/services';
import { toast } from '@/utils/bridge'
import RecordList from './components/RecordList';
import { SpinLoading } from 'antd-mobile'
import { Mask } from 'antd-mobile'
function index() {
  const { t, i18n } = useTranslation()
  const [viewDialog, setViewDialog] = useState(false)
  const [recordList, setRecordList] = useState<LuckyGift.RecordInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const handleViewDialog = useCallback(async (view: boolean) => {
    setViewDialog(view)
    if (view) {
      setLoading(true)
      const res = await getRecordData()
      setLoading(false)
      if (!res) return
      const { code, message, data } = res as Response<LuckyGift.RecordInfo[]>
      if (code !== 200) {
        toast(message)
        return
      }
      setRecordList(data)
    }
  }, [])

  return (
    <>
      <div className="cmp-records" onClick={() => handleViewDialog(true)}>
        <img className="cmp-records__bg" src={ImageRule} />
        <span>{t('LuckyGift.Records')}</span>
      </div>
      <Mask visible={viewDialog} onMaskClick={() => setViewDialog(false)} className='cmp-records__dialog'>
        <div className="cmp-records__dialog-content">
          <img
            src={i18n.language === 'ar' ? RecordsAr : RecordsEn}
            className="cmp-records__dialog-title"
          />
          <div className="cmp-records__dialog-list">
            {loading ? <div className="records-loading"><SpinLoading /></div> : <RecordList recordList={recordList} />}
          </div>
        </div>
      </Mask>
    </>
  )
}

export default memo(index)
