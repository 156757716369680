import React, { memo, useEffect, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'

interface PropsType {
    num: string
    img: string
}
function index(props: PropsType) {
    const { t } = useTranslation()
    const [state, setState] = useState<{
        icon: string
        number: string
    }>({
        icon: props.img,
        number: props.num
    })

    useEffect(() => {
        setState({
            icon: props.img,
            number: props.num
        })
    }, [JSON.stringify(props)])

    return (
        <div className='cmp-rewards-pool'>
            <span className='cmp-rewards-pool__title'>{t('RoomSupport.RewardsPool')}</span>
            <div className='cmp-rewards-pool__rewards'>
                <div>
                    {state.icon && <img src={state.icon} alt="" />}
                </div>
                <span>{state.number}</span>
            </div>
        </div>
    )
}

export default memo(index)