import { service } from '@/utils/net'

// 首页
export async function getIndex() {
  return service<Response<National.IndexItem[]>>({
    method: 'GET',
    url: 'h5doings/saudi/activity/home'
  }).then((res) => res?.data)
}

// 排行榜
export async function getRank() {
  return service<Response<National.RankInfo>>({
    method: 'GET',
    url: 'h5doings/saudi/activity/rank'
  }).then((res) => res?.data)
}