import React, { memo, useCallback, useEffect, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { getRankList } from '@RamadanAnswer/services';
import { toast } from '@/utils/bridge';
import { cloneDeep } from 'lodash-es';

import ImgRankIcon from '@RamadanAnswer/images/rank-icon.webp';
import ImgRankEmpty from '@RamadanAnswer/images/rank-empty.webp';

import Empty from '@/components/Empty';
import { SpinLoading } from 'antd-mobile';

function index() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const [rank, setRank] = useState<{
        userRank: RamadanAnswer.RankList,
        rankList: RamadanAnswer.RankList[]
    }>({
        userRank: {} as RamadanAnswer.RankList,
        rankList: []
    })

    const fetchRankList = useCallback(async () => {
        setLoading(true)
        const res = await getRankList()
        setLoading(false)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return
        const rankTemp = cloneDeep(data.rankList.map((item, index) => { return { ...item, index: index + 1 } }))
        const userTemp = cloneDeep(data.userRank) || {}
        const userIndex: number = rankTemp.findIndex(item => item?.uid === userTemp?.uid)
        if (Object.values(userTemp).length === 0) return
        if (userIndex !== -1) {
            userTemp.index = rankTemp[userIndex].index
        } else {
            userTemp.index = 0
        }
        setRank({
            userRank: userTemp,
            rankList: rankTemp
        })
    }, [])
    useEffect(() => {
        fetchRankList()
    }, [])
    return (
        <div className='cmp-rank'>
            <span className='cmp-rank__title'>{t('RamadanAnswer.MonthlyRank')}</span>
            {rank.rankList?.length > 0 ?
                <div className='cmp-rank__list'>
                    {rank.rankList.map(item =>
                        <div key={item.uid} className='cmp-rank__list-item'>
                            <div className='cmp-rank__list-item-index'>
                                <div className={`rank${item.index}`}>{item.index && item.index <= 3 ? '' : item.index}</div>
                            </div>
                            <div className='cmp-rank__list-item-info'>
                                <div className="left">
                                    <img className='cmp-rank__list-item-avatar' src={item.avatar} alt=''></img>
                                    <div className='cmp-rank__list-item-nick'>{item.nick}</div>
                                </div>
                                <div className="right">
                                    <img className='cmp-rank__list-item-icon' src={ImgRankIcon} alt=''></img>
                                    <span className='cmp-rank__list-item-num'>{item.correctNumber}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='cmp-rank__list-user'>
                        <div className='cmp-rank__list-user-index'>
                            <div className={`rank${rank.userRank.index}`}>{rank.userRank.index === 0 ? '-' : rank.userRank.index && rank.userRank.index <= 3 ? '' : rank.userRank.index}</div>
                        </div>
                        <div className='cmp-rank__list-user-info'>
                            <div className="left">
                                <img className='cmp-rank__list-user-avatar' src={rank.userRank.avatar} alt=''></img>
                                <div className='cmp-rank__list-user-nick'>{rank.userRank.nick}</div>
                            </div>
                            <div className="right">
                                <img className='cmp-rank__list-user-icon' src={ImgRankIcon} alt=''></img>
                                <span className='cmp-rank__list-user-num'>{rank.userRank.correctNumber}</span>
                            </div>
                        </div>
                    </div>
                </div>
                : loading ? <div className='cmp-rank__loading'> <SpinLoading /></div> :
                    <Empty image={ImgRankEmpty} description={'Common.NoData'} />
            }
        </div>
    )
}

export default memo(index)