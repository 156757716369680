import React, { memo, useCallback, useRef, useState } from 'react'
import './index.scss'
import clsx from 'clsx'
import ImagerewardLine from '@Invite/images/reward-detail-line.webp'
import ImageCoin from '@Invite/images/coin.png'
import { extractCoin } from '../../services'
import { t } from 'i18next'
import { toast } from '@/utils/bridge'

interface ExtactGoldsProps {
  openExtactView: (v: boolean) => void
  fetchMyInvitation: () => void
  extactView: boolean
  canExtractGolds: number | undefined
}

function index(props: ExtactGoldsProps) {
  const {
    extactView,
    openExtactView,
    canExtractGolds = 0,
    fetchMyInvitation
  } = props

  const [controlVal, setControlVal] = useState('')

  const refControlVal = useRef(controlVal)
  refControlVal.current = controlVal

  const handleConfirm = useCallback(async () => {
    const coinNum = Number(refControlVal.current)
    if (
      coinNum > canExtractGolds ||
      canExtractGolds === 0 ||
      coinNum === 0 ||
      isNaN(coinNum)
    ) {
      toast(t('Invite.ExtractionFailed'))
      return
    }
    const res = await extractCoin({ coinNum })
    if (!res) return

    const { code, message } = res as Response
    if (code !== 200) {
      toast(message)
      return
    }

    toast(t('Common.Success'))
    openExtactView(false)
    fetchMyInvitation()
  }, [canExtractGolds])

  return (
    <div className="extact-golds">
      <div className={clsx('extact-golds-dialog', { hide: !extactView })}>
        <div className="body">
          <div className="header">
            <img className="left__line" src={ImagerewardLine} />
            <span>{t('Invite.ExtactGold')}</span>
            <img className="right__line" src={ImagerewardLine} />
          </div>

          <div className="title">{t('Invite.CanExtractGolds')}</div>
          <div className="can-extact-total">
            <img src={ImageCoin} />
            <input type="text" disabled value={canExtractGolds} />
          </div>
          <div className="title">{t('Invite.ExtactGold')}</div>
          <div className="extact-count">
            <input
              type="text"
              onChange={(e) => setControlVal(e.target.value)}
              value={controlVal}
              placeholder={t('Invite.PleaseEnter').toString()}
            />
            <div
              className="extact-count-auto__input"
              onClick={() => setControlVal(canExtractGolds.toString())}>
              {t('Invite.AllExtract')}
            </div>
          </div>

          <div className="operate">
            <div
              className="confirm-btn cancel-btn"
              onClick={() => openExtactView(false)}>
              {t('Common.Cancel')}
            </div>
            <div className="confirm-btn" onClick={handleConfirm}>
              {t('Common.Confirm')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(index)
