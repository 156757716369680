import React, { memo, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'
import ImgTitleAr from '@LuckyGift/images/lucky-chest-ar.webp'
import ImgTitleEn from '@LuckyGift/images/lucky-chest-en.webp'
import ImgGoldIcon from '@LuckyGift/images/gold-icon.webp'
import ImgGoldIconNoShadow from '@LuckyGift/images/gold-icon-noshadow.webp'
import { Swiper } from 'antd-mobile'
import { sampleSize } from 'lodash-es'
import { px2rem } from '@/utils/tool';

interface PropsType {
    homeData: LuckyGift.HomeInfo
    fetchThisDay?: () => void
}

interface TopThreeType {
    img: string
    gold: number
}

function index({ homeData }: PropsType) {
    const { t, i18n } = useTranslation()
    const [topThree, setTopThree] = useState([] as TopThreeType[])

    const randomAvatar = useMemo(() => {
        if (Object.values(homeData).length === 0) {
            //`Swiper` needs at least one child.
            return <Swiper.Item>
                {''}
            </Swiper.Item>
        }
        const randomArr = []
        for (let i = 0; i < 10; i++) {
            const avatarList = sampleSize(homeData.rank, 3)
            const verticalItems = (
                <Swiper.Item key={`swiper-` + i}>
                    <div className='avatar-list'>
                        {avatarList.map((item, index) => (
                            <img key={`avatar-` + index} src={item.avatar}></img>
                        ))}
                    </div>
                </Swiper.Item>
            )
            randomArr.push(verticalItems)
        }
        return randomArr
    }, [homeData])
    useEffect(() => {
        if (Object.values(homeData).length === 0) return
        setTopThree(homeData.rewardRankList.map(gold => ({
            img: ImgGoldIcon,
            gold
        })))
    }, [homeData])
    return <>
        <div className='chest'>
            <img className='title' src={i18n.language === 'ar' ? ImgTitleAr : ImgTitleEn}></img>
            <div className="header">
                <div className="left">
                    <span>{t('LuckyGift.TotalCoins') + ':'}</span>
                    <div className="gold">
                        <img src={ImgGoldIconNoShadow}></img>
                        <span>{homeData?.totalCoin}</span>
                    </div>
                </div>
                <div className="right">
                    <span>{t('LuckyGift.Participants') + ':'}</span>
                    <div className="avatar">
                        <span>{homeData?.totalPeople}</span>
                        <Swiper loop={true} allowTouchMove={false} indicator={() => null} autoplay={true} autoplayInterval={3000} direction='vertical' style={{ '--height': px2rem(17) }}>
                            {randomAvatar}
                        </Swiper>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="top-three">
                    {topThree.length ? topThree.map((item, i) => (
                        <div className="item" key={i}>
                            <div className="gold" >
                                <img src={item.img}></img>
                                <span>{item.gold}</span>
                            </div>
                        </div>)) : ''}
                </div>
                {/* {isRewards ? <div className='rewards-box'>
                    <div className="item">
                        <div className="gold" >
                            <img src={ImgGoldIcon}></img>
                            <span>{88888}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="gold" >
                            <img src={ImgGoldIcon}></img>
                            <span>{88888}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="gold" >
                            <img src={ImgGoldIcon}></img>
                            <span>{88888}</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="gold" >
                            <img src={ImgGoldIcon}></img>
                            <span>{88888}</span>
                        </div>
                    </div>
                </div> : <div className='chest-box'>
                    <img className="gold-box" src={homeData.hasOpenBox ? ImgChestBoxEmpty : ImgChestBox}></img>
                    <div className="btn-get" onClick={handleGet}>
                        <img className="bg" src={homeData.canOpenBox ? homeData.hasOpenBox ? ImgChestBtnGray : ImgChestBtn : ImgChestBtnGray}></img>
                        <img className={clsx('get', { 'done': homeData.canOpenBox ? homeData.hasOpenBox ? true : false : true })} src={homeData.canOpenBox ? homeData.hasOpenBox ? ImgDone : ImgGet : ImgDone}></img>
                    </div>
                </div>} */}
            </div>
        </div>
    </>
}

export default memo(index)