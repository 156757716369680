import React, { memo, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'
import moment from 'moment';

function index() {
    const { t } = useTranslation()
    const [countdown, setCountdown] = useState([] as Array<number>);


    const calculateCountdown = useCallback(() => {
        const now = moment();
        const target = moment().add(1, 'day').set({ hour: 0, minute: 5, second: 0, millisecond: 0 });
        const duration = moment.duration(target.diff(now));

        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        return [days, hours, minutes, seconds];
    }, [])
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <div className='countdown'>
            <span className='title'>{t('LuckyGift.Countdown') + ':'}</span>
            {Object.values(countdown).length ?
                (countdown.map((key, i) => (<div key={i} className="time">
                    <span>{key}</span>
                </div>))) : ''}
        </div>
    )
}

export default memo(index)