import React, { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageRule from '@Invite/images/rule-bg.webp'
import ImageArrow from '@Invite/images/rule-arrow.webp'
import './index.scss'
import clsx from 'clsx'
import { resetScroll, stopScroll } from '@/utils/tool'

function index() {
  const { t } = useTranslation()
  const [viewDialog, setViewDialog] = useState(false)

  const handleViewDialog = useCallback((view: boolean) => {
    if (view) {
      stopScroll()
    } else {
      resetScroll()
    }
    setViewDialog(view)
  }, [])

  return (
    <>
      <div className="cmp-rule" onClick={() => handleViewDialog(true)}>
        <img className="cmp-rule__bg" src={ImageRule} />
        <span>{t('Common.Rule')}</span>
      </div>
      <div
        className={clsx('cmp-rule__dialog', {
          hide: !viewDialog
        })}
        onClick={() => handleViewDialog(false)}>
        <div className="cmp-rule__dialog-content">
          <span className="cmp-rule__dialog-title">{t('Invite.Rule')}</span>
          {new Array(2).fill(0).map((_, i) => (
            <img className="cmp-rule__dialog-arrow" src={ImageArrow} key={i} />
          ))}
          <div className="cmp-rule__dialog-list">
            {new Array(6).fill(0).map((_, i) => (
              <div className="cmp-rule__dialog-item" key={i}>
                {t(`Invite.Rule${i + 1}`, { APP: process.env.REACT_APP_DISPLAY_NAME })}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(index)
