import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import './index.scss'
import clsx from 'clsx'
import ImagerewardLine from '@Invite/images/reward-detail-line.webp'
import DetailList from '../DetailList'
import { t } from 'i18next'
import { toast } from '@/utils/bridge'
import { getMyInvitationDetail } from '../../services'
import Empty from '../Empty'
import Loading from '@/components/Loading'

interface DetailProps {
  openDetail: (v: boolean) => void
  viewDetail: boolean
}

const tabText = [
  t('Invite.GoldRevenue'),
  t('Invite.GoldDetails'),
  t('Invite.Inviter')
]
export const tabKey = { goldRevenue: 1, goldDetail: 2, inviter: 3 }
function index(props: DetailProps) {
  const { viewDetail, openDetail } = props
  const [tabVal, setTabVal] = useState<number>(tabKey.goldRevenue)

  const [listData, setListData] = useState<Invite.InvitationDetail>({
    whetherLastPage: false,
    list: [],
    totalCount: 0
  })

  const [changeTabLoading, setChangeTabLoading] = useState<boolean>(false)

  const [listStatus, setListStatus] = useState({
    currentType: tabKey.goldDetail,
    pageNum: 1,
    pageSize: 20
  })

  const refListStatus = useRef(listStatus)
  refListStatus.current = listStatus

  const fetchMyInvitationDetail = useCallback(async (typeVal: number) => {
    let type = typeVal

    // 切换类型重置页码
    if (type) {
      refListStatus.current.currentType = type
      refListStatus.current.pageNum = 1
    }

    // 触底加载
    if (!type) {
      type = refListStatus.current.currentType
      refListStatus.current.pageNum += 1
    }
    if (type) setChangeTabLoading(true)
    const res = await getMyInvitationDetail({
      type,
      pageNum: refListStatus.current.pageNum,
      pageSize: refListStatus.current.pageSize
    })
    if (type) setChangeTabLoading(false)
    if (!res) return

    const { code, message, data } = res as Response<Invite.InvitationDetail>
    if (code !== 200) {
      toast(message)
      return
    }

    setListData((preData) => ({
      ...preData,
      ...data,
      list: typeVal ? data.list : [...preData.list, ...data.list]
    }))

    setListStatus((preData) => ({
      ...preData,
      currentType: type,
      hasMore: !data.whetherLastPage
    }))
  }, [])

  useEffect(() => {
    if (viewDetail) fetchMyInvitationDetail(tabVal)
  }, [fetchMyInvitationDetail, tabVal, viewDetail])

  return (
    <div className="detail">
      <div className={clsx('detail-dialog', { hide: !viewDetail })}>
        <div className="body">
          <div className="header">
            <img className="left__line" src={ImagerewardLine} />
            <span>{t('Invite.Details')}</span>
            <img className="right__line" src={ImagerewardLine} />
          </div>
          <div className="detail-tabs">
            {tabText.map((text, i) => (
              <span
                key={i}
                onClick={() => setTabVal(i + 1)}
                className={clsx('detail-tabs-item', {
                  selected: tabVal === i + 1
                })}>
                {text}
              </span>
            ))}
          </div>

          {tabVal !== tabKey.goldDetail && (
            <div className="total">
              <span>{t('Invite.Total')} </span>
              <span>{listData?.totalCount}</span>
            </div>
          )}

          {!listData?.list?.length && <Empty />}

          <DetailList
            tabVal={tabVal}
            data={changeTabLoading ? [] : listData?.list || []}
            hasMore={!listData.whetherLastPage}
            loadMore={fetchMyInvitationDetail}
          />
          <div className="confirm-btn" onClick={() => openDetail(false)}>
            {t('Common.Confirm')}
          </div>
        </div>
      </div>
      {changeTabLoading && <Loading />}
    </div>
  )
}

export default memo(index)
