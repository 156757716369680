import React, { memo } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'

interface PropsType {
  text?: string
}
const BottomStatement = ({ text }: PropsType) => {
  const { t } = useTranslation()

  return (
    <div className="cmp-bottom-statement" >
      {t(text || 'Common.Statement', { APP: process.env.REACT_APP_DISPLAY_NAME })}
    </div >
  )
}

export default memo(BottomStatement)
