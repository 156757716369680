import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import { getReWardsList } from '../../services'
import { toast } from '@/utils/bridge'

function index() {
    const { t } = useTranslation()
    const [state, setState] = useState<{
        items: RoomSupport.RewardItem[]
    }>({
        items: []
    })
    const refState = useRef(state)
    refState.current = state

    const handleGetRewardsList = useCallback(async () => {
        const res = await getReWardsList()
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        console.log('ddd', res.data)
        setState({
            ...refState.current,
            items: res.data || [],
        })
    }, [])

    useEffect(() => {
        handleGetRewardsList()
    }, [])

    return (
        <div className='cmp-rewards'>
            {state.items.map((item, i) =>
                <div className='cmp-rewards__item' key={i}>
                    <div className='cmp-rewards__item-title'>{item.topName}</div>
                    {item.topName === 'remaining pool' && <div className='cmp-rewards__item-sub'>{t('RoomSupport.LastTips')}</div>}
                    <div className='cmp-rewards__items'>
                        {item.rewardList.map((item, j) => <div className='cmp-rewards__item-box' key={j}>
                            <div>
                                <img src={item.rewardIcon} alt="" />
                            </div>
                            <span>{item.rewardName}</span>
                        </div>)}
                    </div>
                </div>
            )}
        </div>
    )
}

export default memo(index)