import React from 'react'
import ImgBanner from './images/banner.webp';
import ImgReward from './images/reward.webp';
import ImgRule from './images/rule.webp';
import './index.scss'
import BottomStatement from '@/components/BottomStatement';
function RechargeSendVip() {
    return (
        <div className="pg-recharge_send_vip">
            <img src={ImgBanner}></img>
            <img src={ImgReward}></img>
            <img src={ImgRule}></img>
            <BottomStatement text='Common.Statement' />
        </div>
    )
}
export default RechargeSendVip
