import React, { memo } from 'react'
import './Medal.scss'
function Medal() {
    return (
        <div className='cmp-medal'>
            <div className='cmp-medal__medal'>
                <img src="https://picsum.photos/200" alt="" />
            </div>
            <span className='cmp-medal__text'>VIP*30 days</span>
        </div>
    )
}

export default memo(Medal)