import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ImgHeaderBannerEn from '@GameRankings/images/header-banner-en.webp'
import ImgHeaderBannerAr from '@GameRankings/images/header-banner-ar.webp'

import BottomStatement from '@/components/BottomStatement';
import TabButtons from '@/components/TabButtons';
import RewardsPool from './components/RewardsPool';
import Rewards from './components/Rewards';
import Rule from './components/Rule';
import Rank from './components/Rank';
import { getRewardsPool } from './services'
import { toast } from '@/utils/bridge'
import moment from 'moment'

function GameRankings() {
    const { t, i18n } = useTranslation()
    const [state, setState] = useState<{
        selectedTab: number
        rewardsPool?: GameRankings.RewardsPool
    }>({
        selectedTab: 0
    })
    const refState = useRef(state)
    refState.current = state

    const tabsTitles = useMemo(() => {
        return ['Common.Rewards', 'Common.Rank', 'Common.Rule']
    }, [])

    const handleGetRewardsPool = useCallback(async () => {
        const res = await getRewardsPool()
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setState({
            ...refState.current,
            rewardsPool: res.data,
        })
    }, [])

    const handleTabChange = useCallback((i: number) => {
        setState({
            ...refState.current,
            selectedTab: i
        })
    }, [])

    useEffect(() => {
        handleGetRewardsPool()
    }, [])

    return (
        <div className={clsx("pg-game-rankings", { 'pg-game-rankings-rank': state.selectedTab === 1 })}>
            {/* {loading < 2 && <Loading />} */}
            <header>
                <img
                    src={i18n.language === 'ar' ? ImgHeaderBannerAr : ImgHeaderBannerEn}
                />
                <div>
                    {state.rewardsPool && <>
                        <span>{t("GameRankings.Phase", { phase: state.rewardsPool.activityPeriod || 1 })}</span>
                        <span>{t('GameRankings.EventTime', {
                            startTime: moment(state.rewardsPool.startTime).format('YYYY-MM-DD'),
                            endTime: moment(state.rewardsPool.endTime).format('YYYY-MM-DD')
                        })}</span>
                        <span>{t('GameRankings.Tips', { number: state.rewardsPool.activityThresholdValue })}</span>
                    </>}
                </div>
            </header>
            <RewardsPool num={state.rewardsPool?.number || '-'} img={state.rewardsPool?.icon || ''} />
            <TabButtons selectedTab={state.selectedTab} setSelectedTab={handleTabChange} tabText={tabsTitles}>
                <div className={clsx('pg-game-rankings__container', { hide: state.selectedTab !== 0 })}>
                    <span className='pg-game-rankings__container-title'>{t('GameRankings.RankRewards')}</span>
                    <Rewards />
                </div>
                <div className={clsx('pg-game-rankings__container', { hide: state.selectedTab !== 1 })}>
                    <span className='pg-game-rankings__container-title'>{t('GameRankings.CurrentRank')}</span>
                    <Rank />
                </div>
                <div className={clsx('pg-game-rankings__container', { hide: state.selectedTab !== 2 })}>
                    <span className='pg-game-rankings__container-title'>{t('GameRankings.ActiveRule')}</span>
                    <Rule />
                </div>
            </TabButtons>
            <BottomStatement />
        </div>
    )
}
export default GameRankings