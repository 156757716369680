import React, { memo } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'

interface PropsType {
  image: any
  description?: string
  imageStyle?: any
}
function Empty({ image, description, imageStyle }: PropsType) {
  const { t } = useTranslation()
  return (
    <div className="cmp-empty">
      <img src={image} style={imageStyle} />
      <span>{description ? t(description) : t('Common.NoData')}</span>
    </div>
  )
}

export default memo(Empty)
