import { service } from '@/utils/net'

// 绑定邀请码
export async function bindInviteCode(params: Invite.BindInviteCodeParams) {
  return service<Response>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/bind',
    params
  }).then((res) => res?.data)
}

// 查看奖励
export async function getReward(params: Invite.GetRewardParams) {
  return service<Response<Invite.RewardInfoItem[]>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getReward',
    params
  }).then((res) => res?.data)
}
// 选择礼包
export async function choicePackage(params: Invite.ChoicePackageParams) {
  return service<Response>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/choicePackage',
    params
  }).then((res) => res?.data)
}

// 首页
export async function getIndex() {
  return service<Response<Invite.IndexInfo>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getIndex'
  }).then((res) => res?.data)
}

// 我的邀请页
export async function getMyInvitation() {
  return service<Response<Invite.InvitationInfo>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getMyInvitation'
  }).then((res) => res?.data)
}

// 邀请详情
export async function getMyInvitationDetail(
  params: Invite.getMyInvitationDetailParams
) {
  return service<Response<Invite.InvitationDetail>>({
    params,
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getMyInvitationDetail'
  }).then((res) => res?.data)
}

// 榜单
export async function getRank(params: Invite.GetRankParams) {
  return service<Response<Invite.RankItem[]>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getRank',
    params
  }).then((res) => res?.data)
}

// 榜单
export async function getRankReward(params: Invite.GetRankRewardParmas) {
  return service<Response<Invite.RankRewardItem[]>>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/getRankReward',
    params
  }).then((res) => res?.data)
}

// 提取金币
export async function extractCoin(params: Invite.ExtractCoinParmas) {
  return service<Response>({
    method: 'GET',
    url: 'h5doings/inviteFriends/activity/extract',
    params
  }).then((res) => res?.data)
}
