import React from 'react'
import ImgNewYear from './images/new-year.webp';
import './index.scss'

function NewYear() {
    return (
        <div className="pg-new-year">
            <img src={ImgNewYear}></img>
        </div>
    )
}
export default NewYear