import React, { memo } from 'react'
import moment from 'moment'
import ImgGoldIcon from '@LuckyGift/images/gold-icon.webp'
import './index.scss'

interface PropsType {
    recordList: LuckyGift.RecordInfo[]
}
function index({ recordList }: PropsType) {
    return <>
        <table cellPadding={0} cellSpacing={0}>
            <thead>
                <tr>
                    <th>Time</th>
                    <th>Records</th>
                </tr>
            </thead>
            <tbody>
                {
                    recordList.map((item, i) => (
                        <tr key={i}>
                            <td>{moment(item.createTime).format('YYYY-MM-DD')}</td>
                            <td>
                                <img src={ImgGoldIcon}></img>
                                <span>{item.goldCoin}</span>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>
}

export default memo(index)