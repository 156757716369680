import React, { memo } from 'react'
import './index.scss'

import ImgCoin from '@/images/coin.png';
import ImgProfile1 from '@CharmAnchor/images/profile1.webp';
import ImgProfile2 from '@CharmAnchor/images/profile2.webp';

import { formatCharmValue } from '@/utils/format';

function index({ rankList }: { rankList: CharmAnchor.RankListItem[] }) {
    return (
        <div className='cmp-rank-list'>
            {rankList.slice(0, 1).map((item, i) =>
                <div className='cmp-rank-list-top1' key={i}>
                    <div className='cmp-rank-list-top1__avatar'>
                        <div className='cmp-rank-list-top1__self'>
                            <img src={item.avatar} alt="" className='avatar' />
                            <img src={ImgProfile1} alt="" className='profile' />
                        </div>
                        <div className='cmp-rank-list-top1__other'>
                            <img src={item.targetAvatar} alt="" className='avatar' />
                            <img src={ImgProfile2} alt="" className='profile' />
                        </div>
                    </div>
                    <div className='cmp-rank-list-top1__info'>
                        <span className='cmp-rank-list-top1__nick'>{item.nick}</span>
                        <div className='cmp-rank-list-top1__gold'>
                            <span>{formatCharmValue(item.goldNum)}</span>
                            <img src={ImgCoin} alt="" />
                        </div>
                    </div>
                </div>
            )}
            <div className='cmp-rank-list-content'>
                {rankList.slice(1).map((item, i) =>
                    <div className='cmp-rank-list-item' key={i}>
                        <div className="left">
                            <span>{i + 2}</span>
                            <div>
                                <img src={item.avatar} alt="" />
                                <img src={item.targetAvatar} alt="" />
                            </div>
                            <span>{item.nick}</span>
                        </div>
                        <div className="right">
                            <img src={ImgCoin} alt="" />
                            <span>{formatCharmValue(item.goldNum)}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default memo(index)