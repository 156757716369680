import React, { memo, useCallback, useRef, useState } from 'react'
import './index.scss'
import clsx from 'clsx'
import { t } from 'i18next'
import ImageReward1 from '@Invite/images/reward-box1.webp'
import ImageReward2 from '@Invite/images/reward-box2.webp'
import ImagerewardLine from '@Invite/images/reward-detail-line.webp'
import { getReward } from '@Invite/services'
import { toast } from '@/utils/bridge'

const rewards = [
  { img: ImageReward1, text: t('Invite.OrdinaryReward') },
  { img: ImageReward2, text: t('Invite.PremiumReward') }
]
function index() {
  const [viewDialog, setViewDialog] = useState<boolean>(false)
  const [rewardData, setRewardData] = useState<Invite.RewardInfoItem[]>([])

  // 记录数据避免重复请求
  const refData = useRef<{
    rewardData1: Invite.RewardInfoItem[]
    rewardData2: Invite.RewardInfoItem[]
  }>({
    rewardData1: [],
    rewardData2: []
  })

  const fetchRewards = useCallback(async (type: number) => {
    const res = await getReward({ type })
    if (!res) return

    const { code, message, data } = res as Response<Invite.RewardInfoItem[]>
    if (code !== 200) {
      toast(message)
      return
    }
    if (type === 1) {
      refData.current.rewardData1 = data
    } else {
      refData.current.rewardData2 = data
    }

    setRewardData(data)
    setViewDialog(true)
  }, [])

  const handleOpenReward = useCallback((type: number) => {
    if (type === 1 && !refData.current?.rewardData1.length) {
      fetchRewards(type)
    } else if (type === 1 && refData.current?.rewardData1.length) {
      setRewardData(refData.current?.rewardData1)
      setViewDialog(true)
    }

    if ((type === 2 || type === 3) && !refData.current?.rewardData2.length) {
      fetchRewards(2)
    } else if (
      (type === 2 || type === 3) &&
      refData.current?.rewardData2.length
    ) {
      setRewardData(refData.current?.rewardData2)
      setViewDialog(true)
    }
  }, [])

  return (
    <div className="invite-reward">
      <div className="invite-reward-body">
        <div className="title">{t('Invite.InvitationRewards')}</div>

        <div className="level-text">
          <span>LV.0 - LV.4</span>
          <span>I’m LV.5</span>
        </div>

        {rewards.map((data, i) => (
          <div className="reward-item" key={i}>
            <img onClick={() => handleOpenReward(i + 1)} src={data.img} />
            <span>{data.text}</span>
          </div>
        ))}
      </div>

      <div className={clsx('invite-reward__dialog', { hide: !viewDialog })}>
        <div className="invite-reward__dialog-body">
          <div className="header">
            <img className="left__line" src={ImagerewardLine} />
            <span>{t('Invite.CurrentPackage')}</span>
            <img className="right__line" src={ImagerewardLine} />
          </div>

          <div className="tip">{t('Invite.BindInvitationPickUp')}</div>

          <div className="reward-list">
            {rewardData.map((item, i) => (
              <div className="reward-list-item" key={i}>
                <div className="bg">
                  <img src={item.picUrl} />
                </div>
                <span>{item.goodsName}</span>
              </div>
            ))}
          </div>
          <div className="confirm-btn" onClick={() => setViewDialog(false)}>
            {t('Common.Confirm')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(index)
