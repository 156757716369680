import React, { memo, useCallback, useEffect, useState } from 'react'
import './index.scss'
import ImagerewardLine from '@Invite/images/reward-detail-line.webp'
import ImageCoin from '@Invite/images/coin.png'
import Share from '../Share'
import ExtactGolds from '../ExtactGolds'
import Detail from '../Detail'
import { t } from 'i18next'
import { toast } from '@/utils/bridge'
import { getMyInvitation } from '@Invite/services'

const myData = [
  { title: t('Invite.TotalGoldRevenue'), data: 0 },
  { title: t('Invite.GoldAfter30Days'), data: 0 },
  { title: t('Invite.TotalNumberOfInviter'), data: 0 },
  { title: t('Invite.InviterWith30Days'), data: 0 }
]
function index() {
  const [myInvitationInfo, setMyInvitationInfo] =
    useState<Invite.InvitationInfo>()
  const [viewShare, setViewShare] = useState<boolean>(false)
  const [extactView, setExtactView] = useState<boolean>(false)
  const [viewDetail, setViewDetail] = useState<boolean>(false)
  const [myDataStatus, setMyDataStatus] = useState(myData)

  const fetchMyInvitation = useCallback(async () => {
    const res = await getMyInvitation()
    if (!res) return

    const { code, message, data } = res as Response<Invite.InvitationInfo>
    if (code !== 200) {
      toast(message)
      return
    }

    setMyInvitationInfo(data)
    setMyDataStatus((preData) => {
      const newData = preData.map((item, i) => {
        let newItem = item
        if (i === 0) newItem = { ...newItem, data: data.totalGoldRevenue }
        if (i === 1) newItem = { ...newItem, data: data.goldAfterThirtyDays }
        if (i === 2) newItem = { ...newItem, data: data.totalInviterCount }
        if (i === 3)
          newItem = { ...newItem, data: data.withinThirtyDaysInviterCount }
        return newItem
      })
      return newData
    })
  }, [])

  useEffect(() => {
    fetchMyInvitation()
  }, [])

  const handleCopy = useCallback((data: string | undefined) => {
    const ele = document.createElement('input')
    ele.setAttribute('type', 'textarea')
    document.body.appendChild(ele)
    ele.value = data || ''
    ele.select()
    ele.setSelectionRange(0, ele.value.length)
    document.execCommand('copy')
    document.body.removeChild(ele)
    toast(t('Invite.CopySuccess'))
  }, [])
  return (
    <div className="my-invitation">
      <div className="mime">
        <div className="user-info">
          <img src={myInvitationInfo?.avatar} />
          <div className="user-info-nick">
            <p>{myInvitationInfo?.nick}</p>
            <p>ID: {myInvitationInfo?.erbanNo}</p>
          </div>
        </div>
        <div className="my-invitation-code">
          <span>{myInvitationInfo?.inviteCode}</span>
          <div
            onClick={() => handleCopy(myInvitationInfo?.inviteCode)}
            className="copy">
            {t('Invite.Copy')}
          </div>
        </div>

        <div className="invite-friends--btn" onClick={() => setViewShare(true)}>
          {t('Invite.InviteFriendsNow')}
        </div>
      </div>

      <div className="my-data">
        <div className="my-data-header">
          <img className="left__line" src={ImagerewardLine} />
          <span>{t('Invite.MyData')}</span>
          <img className="right__line" src={ImagerewardLine} />
        </div>
        <div className="my-data-body">
          <div className="data-list">
            {myDataStatus.map((item, i) => (
              <div className="data-list-item" key={i}>
                <div className="title">{item.title}</div>
                <span>{item.data}</span>
              </div>
            ))}
          </div>

          <div className="extract-golds-tip">{t('Invite.CanExtractGolds')}</div>

          <div className="my-income">
            <img src={ImageCoin} />
            <span>{myInvitationInfo?.canExtractGolds || 0}</span>
          </div>
          <div className="action">
            <div className="action-btn" onClick={() => setViewDetail(true)}>
              {t('Invite.Details')}
            </div>
            <div className="action-btn" onClick={() => setExtactView(true)}>
              {t('Invite.ExtractGolds')}
            </div>
          </div>
        </div>
      </div>

      <Share openView={setViewShare} view={viewShare} handleCopy={handleCopy} />
      <Detail viewDetail={viewDetail} openDetail={setViewDetail} />
      <ExtactGolds
        extactView={extactView}
        openExtactView={setExtactView}
        fetchMyInvitation={fetchMyInvitation}
        canExtractGolds={myInvitationInfo?.canExtractGolds}
      />
    </div>
  )
}

export default memo(index)
