import React, { memo, useRef } from 'react'
import './index.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type TabButtonsProps = {
  isRankTabs?: boolean
  selectedIndex: number
  onChange: (i: number) => void
}

function TabButtons(props: TabButtonsProps) {
  const { t } = useTranslation()
  const { onChange, selectedIndex, isRankTabs = false } = props
  const refText = useRef<string[][]>([
    [t('Invite.BindInvitationCode'), t('Invite.IncomeRanking')],
    [t('Invite.MyInvitation'), t('Invite.InvitationRanking')],
    [t('Invite.Rank'), t('Invite.Rewards')]
  ])
  return (
    <div className={clsx('tabs', { 'no-margin': isRankTabs })}>
      {[0, 1, 2].map((key) => (
        <div
          className={clsx('tabs-item', {
            selected: selectedIndex === key,
            isRankTabs
          })}
          key={key}
          onClick={() => onChange(key)}>
          <span>{refText.current[key][Number(isRankTabs)]}</span>
        </div>
      ))}
    </div>
  )
}

export default memo(TabButtons)
