import React from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import reportWebVitals from '@/reportWebVitals'
import { ConfigProvider } from 'antd-mobile'
import arLocale from '@/locales/antd-mobile/ar'
import enLocale from 'antd-mobile/cjs/locales/en-US'
import HelmetProviders from '@/components/HelmetProviders'
import Invite from '@/pages/invite'
import National from '@/pages/national'
import LuckyGift from '@/pages/LuckyGift'
import RechargeSendVip from '@/pages/RechargeSendVip'
import RechargeSendMedal from '@/pages/RechargeSendMedal'
import NewYear from '@/pages/NewYear'
import CharmAnchor from '@/pages/CharmAnchor'
import RamadanAnswer from '@/pages/RamadanAnswer'
import RoomSupport from '@/pages/RoomSupport'
import GameRankings from './pages/Game/Rankings'
import FruitWheel from './pages/Game/FruitWheel'
import AgencyHonor from './pages/AgencyHonor'
import '@/components/i18n'
import '@/index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const locale = window.lang === 'ar' ? arLocale : enLocale
root.render(
  <ConfigProvider locale={locale}>
    <HelmetProviders>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="invite" element={<Invite />} />
            <Route path="national" element={<National />} />
            <Route path="lucky-gift" element={<LuckyGift />} />
            <Route path="recharge-send-vip" element={<RechargeSendVip />} />
            <Route path="recharge-send-medal" element={<RechargeSendMedal />} />
            <Route path="new-year" element={<NewYear />} />
            <Route path="charm-anchor" element={<CharmAnchor />} />
            <Route path="ramadan-answer" element={<RamadanAnswer />} />
            <Route path="room-support" element={<RoomSupport />} />
            <Route path="agency-honor" element={<AgencyHonor />} />
            <Route path="game">
              <Route path="rankings" element={<GameRankings />} />
              <Route path="fruit-wheel" element={<FruitWheel />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProviders>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
