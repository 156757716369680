import React, { memo, useEffect, useRef, useState } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import './index.scss'

export type HelmetProvidersProps = {
  title?: string | null
  children: any
  width?: string
  initialScale?: string
  minimumScale?: string
  maximumScale?: string
  prefetchMedias?: string[]
}
const HelmetProviders = (props: HelmetProvidersProps) => {
  const {
    title = `${process.env.REACT_APP_DISPLAY_NAME}`,
    children,
    prefetchMedias = [],
    width = 'device-width', // 设置width的默认值为'device-width'
    initialScale = '1.0', // 设置initialScale的默认值为'1.0'
    minimumScale = '1.0', // 设置minimumScale的默认值为'1.0'
    maximumScale = '1.0' // 设置maximumScale的默认值为'1.0'
  } = props
  const [state, setState] = useState<{
    top?: string
    bottom?: string
  }>({})
  const refState = useRef(state)
  refState.current = state

  useEffect(() => {
    let top, bottom
    if (window.query.safeTop) {
      top = window.query.safeTop + 'px'
    }
    if (window.query.safeBottom) {
      bottom = window.query.safeBottom + 'px'
    }
    setState({
      top,
      bottom
    })
  }, [props])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <link rel="preconnect" href={process.env.REACT_APP_DNS} />
        <link rel="dns-prefetch" href={process.env.REACT_APP_DNS} />
        <meta
          name="viewport"
          content={`width=${width}, initial-scale=${initialScale}, minimum-scale=${minimumScale}, maximum-scale=${maximumScale}`}></meta>
        {prefetchMedias.map((media, i) => (
          <link rel="prefetch" href={media} key={i} />
        ))}
      </Helmet>

      <div className={'cmp-safe-area top'} style={{ paddingTop: state.top }} />
      {children}
      <div
        className="cmp-safe-area bottom"
        style={{ paddingBottom: state.bottom }}
      />
    </HelmetProvider>
  )
}

export default memo(HelmetProviders)
