import React, { useMemo, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ImgHeaderBannerEn from '@RamadanAnswer/images/header-banner-en.webp'
import ImgHeaderBannerAr from '@RamadanAnswer/images/header-banner-ar.webp'

import Loading from '@/components/Loading';
import BottomStatement from '@/components/BottomStatement';
import TabButtons from '@/components/TabButtons';
import Rule from './components/Rule';
import Questions from './components/Questions';
import Rank from './components/Rank';
import Rewards from './components/Rewards';

function RamadanAnswer() {
    const { i18n } = useTranslation()
    const [loading, setLoading] = useState<number>(0)
    const [selectedTab, setSelectedTab] = useState(0)

    const tabsTitles = useMemo(() => {
        return ['RamadanAnswer.Questions', 'Common.Rank', 'Common.Rewards']
    }, [])

    return (
        <div className="pg-ramadan-answer">
            {loading < 2 && <Loading />}
            <img
                src={i18n.language === 'ar' ? ImgHeaderBannerAr : ImgHeaderBannerEn}
                className="pg-ramadan-answer__banner"
            />
            <Rule />
            <TabButtons selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabText={tabsTitles}>
                {/* <div className={clsx({ hide: selectedTab !== 0 })}>
                    <Questions setLoading={setLoading} />
                </div> */}
                {selectedTab === 0 && <Questions setLoading={setLoading} />}
                {selectedTab === 1 && <Rank />}
                <div className={clsx({ hide: selectedTab !== 2 })}>
                    <Rewards />
                </div>
            </TabButtons>
            <BottomStatement />
        </div>
    )
}
export default RamadanAnswer