import React, { memo } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import IMageNoData from '@Invite/images/no-data.webp'

function index() {
  const { t } = useTranslation()
  return (
    <div className="cmp-empty">
      <img src={IMageNoData} />
      <span>{t('Common.NoData')}</span>
    </div>
  )
}

export default memo(index)
