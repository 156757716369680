import i18n from '../components/i18n'

export function val2str(val: number): string {
  const str = val.toString()
  const ind = str.indexOf('.')
  return ind > -1 ? str.slice(0, ind + 2) : str
}

export function time2str(val: number): string {
  let str = val2str(val)
  const ind = str.indexOf('.')
  if (ind === -1) return str

  str = str.replace(/\.(\d)/, ($, $1) => {
    const num = Number($1)
    return num >= 5 ? `.5` : ''
  })
  return str
}

export function formatCharmValue(val = 0): string {
  return val > 1000000
    ? val2str(val / 1000000) + 'M'
    : val > 1000
    ? val2str(val / 1000) + 'K'
    : val.toString()
}

export function formatGuildTime(val: number): string {
  return time2str(val / 60) + i18n.t('Common.H')
}

// 只取数字（目的：限制只能输入阿拉伯数字）
export function onlyNumber(number: string | number) {
  const numberArr = number.toString().match(/\d+/g) || []
  return numberArr.join('')
}

// 数字转金额格式
export function formatMoney(number: string | number, thousandsSeparator = ',') {
  // 取数字部分
  number = onlyNumber(number)
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)
}
