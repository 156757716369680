export const BusinessConst = {
  GuildExit: 1
}

export const PageConst = {
  Reward: 1,
  PersonalHome: 2
}

export const downloadLink = ''
