import React, { memo } from 'react'
import './index.scss'
import clsx from 'clsx'
import { openPage } from '@/utils/bridge'
import { PageConst } from '@/utils/constant'

type RankItemProps = {
  item: Invite.RankItem
  index: number
  icon: string
}

const RankItem = (props: RankItemProps) => {
  const { index, item } = props

  return (
    <div className={clsx('rank-item', { first: index === 4 })}>
      <div className="rank-item-left">
        <span className="rank-item-left-sort">{index}</span>
        <img
          className="rank-item-left-avatar"
          src={item?.avatar}
          onClick={() => openPage(PageConst.PersonalHome, item.uid)}
        />
        <div className="rank-item-left-nick">
          <span>{item?.nick}</span>
        </div>
      </div>
      <div className="rank-item-right">
        <img className="ferris-top-coin" src={props.icon} />
        <span>{item?.number}</span>
      </div>
    </div>
  )
}

export default memo(RankItem)
