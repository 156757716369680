import React, { memo, useMemo, useState } from 'react'
import './index.scss'

import Dialog from '@/components/Dialog';

function index() {
    const [viewDialog, setViewDialog] = useState(false)
    const rule = useMemo(() => {
        return [
            `1. أرسل هدايا مخصصة إلى المضيف ورتبها وفقًا لعدد جواهر الهدايا التي استلمها المضيف.
            ملاحظة: يمكن فقط للمستخدمين الذين لديهم حالة المضيف المشاركة.ولن يتم احتساب المستخدمين غير المضيفين في الترتيب.
            في حالة التعادل، سيحصل المضيف ذو مستوى الجاذبية الأعلى على المكافأة
            لا يتم تضمين المضيف الرسمية في هذا النشاط`,
            '2 .سيتم ترتيب قائمة الثروة وفقًا لعدد العملات الذهبية المقدمة كهدايا',
            '3 .سيتم إرسال المكافآت بعد يوم أو يومين عمل من انتهاء الحدث'
            , ' 4 .لا علاقة لهذا النشاط بشركة Apple، وتحتفظ نادي بحق الترجمة الفورية النهائية'
        ]
    }, [])
    return <>
        <div className='cmp-rule' onClick={() => setViewDialog(true)}>
            <Dialog viewDialog={viewDialog} setViewDialog={setViewDialog} title="قواعد" viewClose>
                <div className='text'>
                    {rule.map((text, i) =>
                        <span key={i}>{text}</span>
                    )}
                </div>
            </Dialog>
        </div>
    </>
}

export default memo(index)