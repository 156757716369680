import React, { memo } from 'react'
import { SpinLoading } from 'antd-mobile'
import './index.scss'

const Loading = () => (
  <div className="cmp-loading">
    <SpinLoading />
  </div>
)

export default memo(Loading)
