import { service } from '@/utils/net'

// 奖池
export async function getRewardsPool(): Promise<
  Response<GameRankings.RewardsPool>
> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRewardsPool',
    params: {
      activityType: 2
    }
  }).then((res) => res?.data)
}

// 开宝箱
export async function getReWardsList(): Promise<
  Response<GameRankings.RewardItem[]>
> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRewards'
  }).then((res) => res?.data)
}

// 榜单
export async function getRank(): Promise<Response<GameRankings.RankResponse>> {
  return service({
    method: 'GET',
    url: '/h5doings/room/activity/getRank',
    params: {
      activityType: 2
    }
  }).then((res) => res?.data)
}
