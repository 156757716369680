import React, { memo, useMemo } from 'react'
import './index.scss'
import clsx from 'clsx'
import ImgCoins1 from '@RechargeSendMedal/images/coins1.webp';
import ImgCoins2 from '@RechargeSendMedal/images/coins2.webp';
import ImgCoins3 from '@RechargeSendMedal/images/coins3.webp';
import ImgCoin from '@/images/coin.png';

type TabButtonsProps = {
  selectedIndex: number
  onChange: (i: number) => void
}

function TabButtons(props: TabButtonsProps) {
  const { onChange, selectedIndex } = props
  const tabs = useMemo(() => {
    return [
      {
        icon: ImgCoins1,
        interval: '70k-400k'
      },
      {
        icon: ImgCoins2,
        interval: '500k-900k'
      },
      {
        icon: ImgCoins3,
        interval: '1M-5M'
      }
    ]
  }, [])
  return (
    <div className="cmp-tabs">
      {tabs.map((item, i) => (
        <div
          className={clsx('cmp-tabs-item', {
            selected: selectedIndex === i,
          })}
          key={i}
          onClick={() => onChange(i)}>
          <img className='icon' src={item.icon} alt=''></img>
          <div className='interval'>
            <img src={ImgCoin} alt="" />
            <span>{item.interval}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default memo(TabButtons)
