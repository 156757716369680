import React, { memo } from 'react'
import './index.scss'

import ImgCoin from '@/images/coin.png';

import { formatCharmValue } from '@/utils/format';

function index({ rank }: { rank: CharmAnchor.Rank }) {
    console.log('rank', rank);
    return (
        <div className='cmp-mine'>
            <div className="left">
                <span>{rank.index || '-'}</span>
                <div>
                    <img src={rank.avatar} alt="" className='avatar' />
                    {rank.targetAvatar &&
                        <img src={rank.targetAvatar} alt="" className='target-avatar' />
                    }
                </div>
                <span>{rank.nick}</span>
            </div>
            <div className="right">
                <img src={ImgCoin} alt="" />
                <span>{formatCharmValue(rank.userGold)}</span>
            </div>
        </div>
    )
}

export default memo(index)