import React, { useCallback, useState, useEffect, memo } from 'react'
import { getIndex } from '@National/services'
import { toast } from '@/utils/bridge'
import ImageCoin from '@National/images/coin.webp'
import { SpinLoading } from 'antd-mobile'
import { useTranslation } from 'react-i18next'
import './index.scss'

function index() {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [giftData, setGiftData] = useState<{
    flag: National.IndexItem[]
    list: National.IndexItem[]
  }>({
    flag: [],
    list: []
  })

  const fetchGift = useCallback(async () => {
    setLoading(true)
    const res = await getIndex()
    setLoading(false)
    if (!res) return
    const { code, message, data } = res as Response<National.IndexItem[]>
    if (code !== 200) {
      toast(message)
      return
    }

    const flag: National.IndexItem[] = []
    const list: National.IndexItem[] = []
    data.forEach(element => {
      if (element.isSaudiFlag) {
        flag.push(element)
      } else {
        list.push(element)
      }
    });
    setGiftData({
      flag,
      list
    })
  }, [])

  useEffect(() => {
    fetchGift()
  }, [])

  return (
    <>
      <div className='event-gift'>
        <div className='event-gift-box'>
          <div className='title'>{t('National.EventGift')}</div>
          {loading ? (
            <SpinLoading className="spin-loading" />
          ) : (<>
            <div className="flag-box">
              {giftData.flag.map((item, i) => {
                return (
                  <div className='flag' key={i}>
                    <div className='img-bg'><img src={item.picUrl} /></div>
                    <div className='coin'>
                      <img src={ImageCoin} />
                      <span>{item.goldPrice}</span>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='list'>
              {
                giftData.list.map(item => (
                  <div className='item' key={item.giftId}>
                    <div className='img-bg'>
                      <img src={item.picUrl} />
                    </div>
                    <div className='coin'>
                      <img src={ImageCoin} />
                      <span>{item.goldPrice}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </>)}
        </div>
      </div>
    </>
  )
}

export default memo(index)