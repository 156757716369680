import React, { memo, useCallback, useEffect, useState } from 'react'
import './index.scss'
import { getRank } from '../../services';
import { toast } from '@/utils/bridge';

import ImgGem from '@/images/gem.png';

import { SpinLoading } from 'antd-mobile';
import { formatCharmValue } from '@/utils/format';

function index() {
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState<{
        userRank: RoomSupport.RankItem,
        rankList: RoomSupport.RankItem[]
    }>({
        userRank: {} as RoomSupport.RankItem,
        rankList: []
    })

    const fetchRankList = useCallback(async () => {
        setLoading(true)
        const res = await getRank()
        setLoading(false)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return

        const rankList = data.rankList.map((item, i) => ({ ...item, index: i + 1 }))
        const userRank = data.userRank
        if (userRank) {
            const index = data.rankList.findIndex(item => item.roomId === data.userRank.roomId)
            userRank.index = index + 1
        }

        setState({
            userRank,
            rankList
        })
    }, [])
    useEffect(() => {
        fetchRankList()
    }, [])
    return (
        <div className='cmp-rank'>
            {loading && <SpinLoading />}
            {state.rankList?.length > 0 &&
                <div className='cmp-rank__list'>
                    {state.rankList.map(item =>
                        <div key={item.roomName} className='cmp-rank__list-item'>
                            <div className='cmp-rank__list-item-index'>
                                <div className={`rank${item.index}`}>{item.index && Number(item.index) <= 3 ? '' : item.index}</div>
                            </div>
                            <div className='cmp-rank__list-item-info'>
                                <img className='cmp-rank__list-item-avatar' src={item.avatar} alt=''></img>
                                <div className='cmp-rank__list-item-nick'>{item.roomName}</div>
                                <img className='cmp-rank__list-item-icon' src={ImgGem} alt=''></img>
                                <span className='cmp-rank__list-item-num'>{formatCharmValue(item.totalNumber)}</span>
                            </div>
                        </div>
                    )}
                </div>
            }
            {state.userRank && <div className='cmp-rank__list-user'>
                <div className='cmp-rank__list-user-index'>
                    <div className={`rank${state.userRank.index}`}>{state.userRank.index === 0 ? '-' : state.userRank.index && state.userRank.index <= 3 ? '' : state.userRank.index}</div>
                </div>
                <div className='cmp-rank__list-user-info'>
                    <img className='cmp-rank__list-user-avatar' src={state.userRank.avatar} alt=''></img>
                    <div className='cmp-rank__list-user-nick'>{state.userRank.roomName}</div>
                    <img className='cmp-rank__list-user-icon' src={ImgGem} alt=''></img>
                    <span className='cmp-rank__list-user-num'>{formatCharmValue(state.userRank.totalNumber)}</span>
                </div>
            </div>}
        </div>
    )
}

export default memo(index)