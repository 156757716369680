import { service } from '@/utils/net'

// 1:钻石流水 2:金豆流水 3:公会周榜, 默认是1
type TActivityType = 1 | 2 | 3
const activityType: TActivityType = 3

// 奖池
export async function getRewardsPool(): Promise<
  Response<RoomSupport.RewardsPool>
> {
  return service({
    method: 'GET',
    params: {
      activityType
    },
    url: '/h5doings/room/activity/getRewardsPool'
  }).then((res) => res?.data)
}

// 获取奖励配置
export async function getReWardsList(): Promise<
  Response<RoomSupport.RewardItem[]>
> {
  return service({
    method: 'GET',
    params: {
      activityType
    },
    url: '/h5doings/room/activity/getRewards'
  }).then((res) => res?.data)
}

// 榜单
export async function getRank(rankType?: 0 | 1): Promise<Response<RoomSupport.RankResponse>> {
  return service({
    method: 'GET',
    params: {
      activityType,
      rankType
    },
    url: '/h5doings/room/activity/getRank'
  }).then((res) => res?.data)
}
