import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ImgHeaderBannerEn from './images/header-banner-en.webp'
import ImgHeaderBannerAr from './images/header-banner-ar.webp'

import TabButtons from '@/components/TabButtons';
import RewardsPool from './components/RewardsPool';
import Rewards from './components/Rewards';
import Rule from './components/Rule';
import Rank from './components/Rank';
import { getRewardsPool } from './services'
import { toast } from '@/utils/bridge'
import moment from 'moment'
import { useDesign } from '@/hooks'

const { prefixCls } = useDesign('pg-fruit-wheel')

function FruitWheel() {
    const { t, i18n } = useTranslation()
    const [state, setState] = useState<{
        selectedTab: number
        rewardsPool?: RoomSupport.RewardsPool
    }>({
        selectedTab: 0
    })
    const refState = useRef(state)
    refState.current = state

    const tabsTitles = useMemo(() => {
        return ['Common.Rewards', 'Common.Rank', 'Common.Rule']
    }, [])

    const fetchGetRewardsPool = useCallback(async () => {
        const res = await getRewardsPool()
        if (!res) return

        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setState({
            ...refState.current,
            rewardsPool: res.data,
        })
    }, [])

    const handleTabChange = useCallback((i: number) => {
        setState({
            ...refState.current,
            selectedTab: i
        })
    }, [])

    useEffect(() => {
        fetchGetRewardsPool()
    }, [])

    return (
        <div className={clsx(prefixCls, { [`${prefixCls}-rank`]: state.selectedTab === 1 })}>
            <header>
                <img
                    src={i18n.language === 'ar' ? ImgHeaderBannerAr : ImgHeaderBannerEn}
                />
                <div>
                    {state.rewardsPool && <>
                        <span>{t("Common.Phase", { phase: state.rewardsPool.activityPeriod || 1 })}</span>
                        <span>{t('Common.EventTime', {
                            startTime: moment(state.rewardsPool.startTime).format('YYYY-MM-DD'),
                            endTime: moment(state.rewardsPool.endTime).format('YYYY-MM-DD')
                        })}</span>
                        <span>{t('Common.BeansTips', { number: state.rewardsPool.activityThresholdValue })}</span>
                    </>}
                </div>
            </header>
            <RewardsPool num={state.rewardsPool?.number || '-'} img={state.rewardsPool?.icon || ''} />
            <TabButtons selectedTab={state.selectedTab} setSelectedTab={handleTabChange} tabText={tabsTitles}>
                <div className={clsx(`${prefixCls}__container`, { hide: state.selectedTab !== 0 })}>
                    <span className={`${prefixCls}__container-title`}>{t('Common.RankRewards')}</span>
                    <Rewards />
                </div>
                <div className={clsx(`${prefixCls}__container`, { hide: state.selectedTab !== 1 })}>
                    <span className={`${prefixCls}__container-title`}>{t('Common.CurrentRank')}</span>
                    <Rank />
                </div>
                <div className={clsx(`${prefixCls}__container`, { hide: state.selectedTab !== 2 })}>
                    <span className={`${prefixCls}__container-title`}>{t('Common.ActiveRule')}</span>
                    <Rule />
                </div>
            </TabButtons>
        </div >
    )
}
export default FruitWheel