import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import ImageRewardsEn from '@National/images/rewards-bg.webp'
import ImageRewardsAr from '@National/images/rewards-bg-ar.webp'
import './index.scss'
function index() {
    const [, i18n] = useTranslation()
    return (
        <>
            <div className="rewards">
                <img src={i18n.language === 'ar' ? ImageRewardsAr : ImageRewardsEn} />
            </div>
        </>
    )
}

export default memo(index)