import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageHeaderBannerEn from '@National/images/header-banner.webp'
import ImageHeaderBannerAr from '@National/images/header-banner-ar.webp'
import Rule from './components/Rule'
import TabButtons from './components/TabButtons'
import EventGift from './components/EventGift'
import Rank from './components/Rank'
import Rewards from './components/Rewards'
import './index.scss'

function National() {
  const [t, i18n] = useTranslation()
  const [selectedTab, setSelectedTab] = useState<number>(0)
  return (
    <div className="pg-national">
      <img
        src={i18n.language === 'ar' ? ImageHeaderBannerAr : ImageHeaderBannerEn}
        className="header-banner"
      />
      <Rule />
      <EventGift />
      <TabButtons selectedIndex={selectedTab} onChange={setSelectedTab} />
      {selectedTab === 0 && <Rank />}
      {selectedTab === 1 && <Rewards />}
      <div className="bottom__tip">{t('National.ExplanationTip', { APP: process.env.REACT_APP_DISPLAY_NAME })}</div>
    </div>
  )
}
export default memo(National)
