import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import './index.scss'
import { Tabs, Swiper } from 'antd-mobile'
import RankTop from '../RankTop'
import clsx from 'clsx'
import RankItem from '../RankItem'
import Rewards from '../Rewards'
import Empty from '../Empty'
import { t } from 'i18next'
import { getRank } from '@Invite/services'
import { toast } from '@/utils/bridge'
import ImageUser from '@Invite/images/icon-user.png'
import ImageCoin from '@Invite/images/coin.png'
import TabButtons from '../TabButtons'

const weekKeys = ['ThisWeek', 'LastWeek']
function index() {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [selectedWeek, setSelectedWeek] = useState<number>(0)

  const [rank, setRank] = useState<{
    this: Invite.RankItem[]
    last: Invite.RankItem[]
    thisEmpty: boolean
    lastEmpty: boolean
  }>({
    this: [],
    last: [],
    thisEmpty: false,
    lastEmpty: false
  })
  const refSwiper = useRef<React.ElementRef<typeof Swiper>>(null)

  const fetchRank = useCallback(async (index: number) => {
    const [resThis, resLast] = await Promise.all([
      getRank({ type: index + 1, dateType: 1 }),
      getRank({ type: index + 1, dateType: 2 })
    ])
    if (!resThis || !resLast) return

    if (resThis.code !== 200) {
      toast(resThis.message)
      return
    }

    if (resLast.code !== 200) {
      toast(resLast.message)
      return
    }

    setRank({
      this: resThis.data,
      last: resLast.data,
      thisEmpty: !resThis.data.length,
      lastEmpty: !resLast.data.length
    })
  }, [])

  useEffect(() => {
    setRank({
      this: [],
      last: [],
      thisEmpty: false,
      lastEmpty: false
    })
    fetchRank(selectedTab)
  }, [selectedTab])

  const handleSelectTab = useCallback((tab: number) => {
    setSelectedTab(tab)
    setSelectedWeek(0)
    refSwiper.current?.swipeTo(0)
  }, [])

  const handleWeekChange = useCallback((key: string) => {
    const index = weekKeys.findIndex((item) => item === key)
    setSelectedWeek(index)
    refSwiper.current?.swipeTo(index)
  }, [])

  return (
    <div
      className={clsx('cmp-rank', {
        rewards: selectedTab === 2
      })}>
      <TabButtons
        selectedIndex={selectedTab}
        onChange={handleSelectTab}
        isRankTabs={true}
      />
      {selectedTab !== 2 && (
        <>
          <Tabs activeKey={weekKeys[selectedWeek]} onChange={handleWeekChange}>
            {weekKeys.map((key) => (
              <Tabs.Tab title={t(`Invite.${key}`)} key={key} />
            ))}
          </Tabs>
          <Swiper
            direction="horizontal"
            loop
            indicator={() => null}
            ref={refSwiper}
            defaultIndex={0}
            onIndexChange={setSelectedWeek}>
            <Swiper.Item>
              <div className="cmp-rank__content">
                {rank.this.slice(0, 3).map((item, i) => (
                  <RankTop
                    key={i}
                    index={i + 1}
                    item={item}
                    icon={selectedTab === 1 ? ImageUser : ImageCoin}
                  />
                ))}
                {rank.this.slice(3, 10).length !== 0 && (
                  <div className="cmp-rank__content-list">
                    {rank.this.slice(3, 10).map((item, i) => (
                      <RankItem
                        key={i}
                        index={i + 4}
                        item={item}
                        icon={selectedTab === 1 ? ImageUser : ImageCoin}
                      />
                    ))}
                  </div>
                )}
                {rank.thisEmpty && <Empty />}
              </div>
            </Swiper.Item>
            <Swiper.Item>
              <div className="cmp-rank__content">
                {rank.last.slice(0, 3).map((item, i) => (
                  <RankTop
                    key={i}
                    index={i + 1}
                    item={item}
                    icon={selectedTab === 1 ? ImageUser : ImageCoin}
                  />
                ))}
                {rank.this.slice(3, 10).length !== 0 && (
                  <div className="cmp-rank__content-list">
                    {rank.last.slice(3, 10).map((item, i) => (
                      <RankItem
                        key={i}
                        index={i + 4}
                        item={item}
                        icon={selectedTab === 1 ? ImageUser : ImageCoin}
                      />
                    ))}
                  </div>
                )}
                {rank.lastEmpty && <Empty />}
              </div>
            </Swiper.Item>
          </Swiper>
        </>
      )}
      {selectedTab === 2 && <Rewards />}
    </div>
  )
}

export default memo(index)
