import React, { useEffect, useCallback, useState, memo } from 'react'
import ImageCoin from '@National/images/coin.webp'
import ImageNone from '@National/images/none.webp'
import { toast } from '@/utils/bridge'
import { useTranslation } from 'react-i18next'
import { getRank } from '@National/services'
import { SpinLoading } from 'antd-mobile'
import clsx from 'clsx'
import './index.scss'
function index() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState<boolean>(false)
    const [topThree, setTopThree] = useState<National.RankItem[]>([])
    const [rankList, setRankList] = useState<National.RankItem[]>([])
    const [userRank, setUserRank] = useState<National.RankItem>({} as National.RankItem)

    const fetchRank = useCallback(async () => {
        setLoading(true)
        const res = await getRank()
        setLoading(false)
        if (!res) return

        const { code, message, data } = res as Response<National.RankInfo>
        if (code !== 200) {
            toast(message)
            return
        }

        const rankListSort = data.rankList.sort((a, b) => b.totalCoin - a.totalCoin).map((item, index) => {
            item.index = index + 1
            return item
        });
        const topThree = [] as National.RankItem[]
        for (const item of rankListSort) {
            if (item.totalCoin >= 500000 && topThree.length < 3)
                topThree.push(item)
            if (topThree.length === 3) break
        }
        let num = topThree.length
        const rankList = rankListSort.slice(topThree.length).map(item => {
            item.index = ++num
            return item
        })

        const userRank = data.userRank
        const userRankIndex = rankListSort.findIndex(item => item.uid === data.userRank.uid)

        if (userRankIndex !== -1) {
            userRank.index = rankListSort[userRankIndex].index
        } else {
            userRank.index = 0
        }

        while (topThree.length < 3) {
            topThree.push({ uid: 0, avatar: ImageNone, nick: '? ? ?', totalCoin: 0, erbanNo: 0 })
        }

        setTopThree(topThree)
        setRankList(rankList)
        setUserRank(userRank)
    }, [])
    useEffect(() => {
        fetchRank()
    }, [])

    return (
        <>
            <div className='rank'>
                {loading ? (
                    <SpinLoading className="spin-loading" />
                ) : (<>
                    <div className='top-three'>
                        {
                            topThree.map((item, i) => (
                                <div className='item' key={i}>
                                    <img className="avatar" src={item.avatar}></img>
                                    <span className='name'>{item.nick}</span>
                                    <div className={clsx('coin', { hide: !item.uid })}>
                                        <img src={ImageCoin} />
                                        <span>{item.totalCoin}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='top3-tip'>
                        {t('National.Top3Tip')}
                    </div>
                    <div className='list'>
                        {rankList.map(item => (
                            <div className='user-item' key={item.uid}>
                                <div className="left">
                                    <span className='index'>{item.index}</span>
                                    <img src={item.avatar} />
                                    <div className="user-info">
                                        <span>{item.nick}</span>
                                        <span>ID:{item.erbanNo}</span>
                                    </div>
                                </div>
                                <div className="right">
                                    <img src={ImageCoin} />
                                    <span>{item.totalCoin}</span>
                                </div>
                            </div>
                        ))}

                    </div>
                    <div className="user-rank">
                        <div className='user-item'>
                            <div className="left">
                                <span className='index'>{userRank.index === 0 ? '-' : userRank.index}</span>
                                <img src={userRank.avatar} />
                                <div className="user-info">
                                    <span>{userRank.nick}</span>
                                    <span>ID:{userRank.erbanNo}</span>
                                </div>
                            </div>
                            <div className="right">
                                <img src={ImageCoin} />
                                <span>{userRank.totalCoin}</span>
                            </div>
                        </div>
                    </div>
                </>)}

            </div>
        </>
    )
}

export default memo(index)