import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './index.scss'
import clsx from 'clsx';

import ImageBanner from '@CharmAnchor/images/banner.webp'
import ImageGift from '@CharmAnchor/images/gift.webp'
import ImageRewardCharm from '@CharmAnchor/images/reward-charm.webp'
import ImageRewardWealth from '@CharmAnchor/images/reward-wealth.webp'
import ImageEmpty from '@CharmAnchor/images/empty.webp'

import Empty from '@/components/Empty';
import Loading from '@/components/Loading';
import BottomStatement from '@/components/BottomStatement';
import Rule from './components/Rule';
import TabButtons from '@/components/TabButtons';
import RankList from './components/RankList';
import Mine from './components/Mine';

import { getRank } from './services';
import { toast } from '@/utils/bridge';

function CharmingAnchor() {
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [rank, setRank] = useState<
        {
            charm: CharmAnchor.Rank
            wealth: CharmAnchor.Rank
        }
    >()
    const tabsTitles = useMemo(() => {
        return ['الجاذبية', 'الثروة', 'مكافآت']
    }, [])

    const fetchGetRank = useCallback(async () => {
        setLoading(true)
        const [wealthRank, charmRank]: [Response<CharmAnchor.Rank>, Response<CharmAnchor.Rank>] = await Promise.all([
            getRank(2),
            getRank(3)
        ])
        setLoading(false)
        if (!wealthRank || !charmRank) return
        if (wealthRank.code !== 200) {
            toast(wealthRank.message)
            return
        }

        if (charmRank.code !== 200) {
            toast(charmRank.message)
            return
        }
        const charmUserIndex = charmRank.data.rankList.findIndex(item => item.erbanNo === charmRank.data.erbanNo)
        if (charmUserIndex !== -1) {
            charmRank.data.index = charmUserIndex + 1
        }
        const wealthUserIndex = wealthRank.data.rankList.findIndex(item => item.erbanNo === wealthRank.data.erbanNo)
        if (wealthUserIndex !== -1) {
            wealthRank.data.index = wealthUserIndex + 1
        }
        setRank({
            charm: charmRank.data,
            wealth: wealthRank.data
        })
    }, [])
    useEffect(() => {
        fetchGetRank()
    }, [])

    return (
        <div className='mirror'>
            <div className='pg-charm-anchor'>
                {loading && <Loading />}
                <header>
                    <img src={ImageBanner} alt='' />
                    <img src={ImageGift} alt="" className='gift-img' />
                    <Rule />
                </header>
                <TabButtons selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabText={tabsTitles} className={clsx({ 'tab-reward': selectedTab === 2 })}>
                    <div className='pg-charm-anchor-rank-title'>{tabsTitles[selectedTab]}</div>
                    {/* charm */}
                    <div className={clsx({ hide: selectedTab !== 0 })}>
                        {
                            rank?.charm.rankList && rank?.charm.rankList.length > 0 ? <RankList rankList={rank.charm.rankList} /> : <Empty image={ImageEmpty} />
                        }
                    </div>
                    {/* wealth */}
                    <div className={clsx({ hide: selectedTab !== 1 })}>
                        {
                            rank?.wealth.rankList && rank?.wealth.rankList.length > 0 ? <RankList rankList={rank.wealth.rankList} /> : <Empty image={ImageEmpty} />
                        }
                    </div>
                    {/* reward */}
                    <div className={clsx('pg-charm-anchor-reward', { hide: selectedTab !== 2 })}>
                        <img src={ImageRewardCharm} alt="" />
                        <img src={ImageRewardWealth} alt="" />
                    </div>
                </TabButtons>
                <BottomStatement />
                {rank &&
                    <Mine rank={selectedTab === 0 ? rank.charm : rank.wealth} />
                }
            </div>
        </div>
    )
}

export default CharmingAnchor