import React, { memo, useRef } from 'react'
import './index.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type TabButtonsProps = {
  selectedIndex: number
  onChange: (i: number) => void
}

function TabButtons(props: TabButtonsProps) {
  const { t } = useTranslation()
  const { onChange, selectedIndex } = props
  const refText = useRef<string[]>(
    [t('National.Rank'), t('National.Rewards')],
  )
  return (
    <div className="tabs">
      {[0, 1].map((key) => (
        <div
          className={clsx('tabs-item', {
            selected: selectedIndex === key,
          })}
          key={key}
          onClick={() => onChange(key)}>
          <span>{refText.current[key]}</span>
        </div>
      ))}
    </div>
  )
}

export default memo(TabButtons)
